import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Box, Stack, Typography, Button } from '@liscio/ui';

import { formSchema } from './formValidationSchema';
import { BiometricsAdornment } from '../BiometricsAdornment/BiometricsAdornment';
import { BiometricsToggle } from '../BiometricsToggle/BiometricsToggle';
import { RememberMeToggle } from '../RememberMe/RememberMeToggle';
import { Link, ErrorDialog } from 'components';
import { useForgotPassword } from 'fetch-utils/users/auth-hooks';
import {
  AuthPasswordInput,
  AuthTextField,
} from 'modules/authenticate/components/FormFields';
import {
  GoogleOAuthV5Login,
  OutlookOAuthV5Login,
} from 'modules/authenticate/components/OAuth';

export interface SignInFormProps {
  isLoading?: boolean;
  onSubmit: (formData: LoginFormInterface) => void;
}

export interface LoginFormInterface {
  email: string;
  password: string;
  enableRememberMe?: boolean;
  enableBiometrics?: boolean;
}

export const SignInForm = ({
  isLoading = false,
  onSubmit,
}: SignInFormProps) => {
  /**
   * LoginForm context
   */
  const {
    control,
    register,
    getValues,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginFormInterface>({
    resolver: yupResolver(formSchema),
  });

  const { setForgotPasswordEmail } = useForgotPassword();

  // Update/reset email field when remember me loads
  const updateRememberMeEmailValue = useCallback(
    (email: string) => reset({ email }),
    [reset]
  );

  // Forgot Password Capture
  const captureForgotPasswordEmail = useCallback(() => {
    const email = getValues('email');
    setForgotPasswordEmail(email);
  }, [getValues, setForgotPasswordEmail]);

  return (
    <>
      <Typography variant="h2" color="white" textAlign="center" mb={4}>
        Sign In
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
          <AuthTextField
            {...register('email')}
            fullWidth
            type="email"
            placeholder="Email"
            error={Boolean(errors?.email)}
            helperText={errors?.email?.message as string}
            disabled={isLoading}
            InputProps={{
              endAdornment: <BiometricsAdornment />,
            }}
          />
          <AuthPasswordInput
            {...register('password')}
            fullWidth
            placeholder="Password"
            error={Boolean(errors?.password)}
            helperText={errors?.password?.message as string}
            disabled={isLoading}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={isLoading}
            size="large"
          >
            Sign In
          </Button>
          <Box display="flex" flexDirection="column" mt={4}>
            <GoogleOAuthV5Login />
          </Box>
          <Box display="flex" flexDirection="column">
            <OutlookOAuthV5Login />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <RememberMeToggle
              name="enableRememberMe"
              resetEmailField={updateRememberMeEmailValue}
              control={control}
            />
            <BiometricsToggle control={control} name="enableBiometrics" />
          </Box>
        </Stack>
      </form>

      <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
        <Link
          onClick={captureForgotPasswordEmail}
          to="/authenticate/forgot-password"
          underline="none"
          color={(theme) => theme.palette.text.secondary}
        >
          Forgot your password?
        </Link>
      </Box>
      <ErrorDialog title="Sign In Error" error={errors.root?.serverError} />
    </>
  );
};

export default SignInForm;
