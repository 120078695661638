import { createContext, useCallback, useContext, useEffect } from 'react';

import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import mixpanelBrowser, { Mixpanel } from 'mixpanel-browser';

import { config } from 'config';
import { useAuth } from 'fetch-utils/users/use-auth';
import { useHomeData } from 'fetch-utils/users/user-hooks';

export type MixpanelContextType = {
  mixpanel?: Mixpanel;
};

export const MixPanelContext = createContext<MixpanelContextType>({});

export type MixpanelProviderProps = {
  children?: React.ReactNode;
};
/**
 * Mixpanel instance provider
 */
export const MixpanelProvider = ({ children }: MixpanelProviderProps) => {
  const mixpanel = config.mixpanel.projectToken
    ? mixpanelBrowser.init(
        config.mixpanel.projectToken,
        {
          // NOTE: Add mixpanel config options here as needed
        },
        'app-mp-instance'
      )
    : undefined;
  const { isLoggedIn } = useAuth();
  const { data: homeData } = useHomeData();

  /**
   * Register mixpanel super properties to be added to all events
   */
  const registerSuperProperties = useCallback(async () => {
    if (mixpanel) {
      // Set cross-platform super properties
      mixpanel.register({
        app: config.app,
        app_version: config.version,
        platform: config.platform,
      });

      // Set native properties
      if (Capacitor.isNativePlatform()) {
        const appInfo = await App.getInfo();
        const deviceInfo = await Device.getInfo();

        mixpanel.register({
          native_app_version: appInfo.version,
          native_app_build: appInfo.build,
          device_manufacturer: deviceInfo.manufacturer,
          device_model: deviceInfo.model,
          device_os_version: deviceInfo.osVersion,
          device_webview_version: deviceInfo.webViewVersion,
          device_ios_version: deviceInfo.iOSVersion,
          device_android_sdk_version: deviceInfo.androidSDKVersion,
        });
      }
    }
  }, [mixpanel]);

  /**
   * Mixpanel identify
   */
  const identify = useCallback(async () => {
    if (mixpanel && homeData?.data) {
      // NOTE: We must re-register super properties as they get cleared on reset
      await registerSuperProperties();

      mixpanel.identify(homeData.data.current_cpa_user_uuid);
      mixpanel.people.set({
        $id: homeData.data.current_cpa_user_uuid,
        app_version: config.version,
        cpa_id: homeData.data.cpa_uuid,
        user_role: homeData.data.userpilot_data.user_role,
        firm_name: homeData.data.userpilot_data.firm_name,
        texting_enabled: !!homeData.data.userpilot_data.is_texting_enabled,
        texts_sent: homeData.data.userpilot_data.texts_sent,
      });
    }
  }, [mixpanel, homeData, registerSuperProperties]);

  // Register super properties to be added to all track events
  useEffect(() => {
    if (mixpanel) {
      registerSuperProperties();
    }
  }, [mixpanel, registerSuperProperties]);

  // Handle user identify
  useEffect(() => {
    if (mixpanel) {
      if (isLoggedIn) {
        identify();
      } else if (!isLoggedIn) {
        // Reset when not logged in
        mixpanel.reset();
      }
    }
  }, [mixpanel, identify, isLoggedIn]);

  return (
    <MixPanelContext.Provider value={{ mixpanel }}>
      {children}
    </MixPanelContext.Provider>
  );
};

/**
 * Hook to access global app mixpanel instance
 * Access the mixpanel instance to track events in your code:
 * https://docs.mixpanel.com/docs/quickstart/track-events
 */
export const useMixpanel = () => {
  const { mixpanel } = useContext(MixPanelContext);
  return mixpanel;
};
