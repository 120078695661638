import { Capacitor } from '@capacitor/core';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';

import { AuthorizedCpaUserCpaData } from '@liscio/api';

import { CpaStateItem } from './cpa.types';
import { config } from 'config';

export const APP_CPA = 'application_cpa';
export const NATIVE_LAST_LOGGED_CPA = 'native_last_logged_cpa';
export const NON_CPA_SUBDOMAINS = ['app', 'firm'];

export const getSavedApplicationCpa = async () => {
  try {
    const { value: cpaData } = await SecureStoragePlugin.get({ key: APP_CPA });
    const cpa = JSON.parse(cpaData) as CpaStateItem;
    return cpa;
  } catch {
    // fail silently
  }
};

export const saveApplicationCpa = async (cpa: CpaStateItem) => {
  try {
    await SecureStoragePlugin.set({ key: APP_CPA, value: JSON.stringify(cpa) });
  } catch {
    // fail silently
  }
};

export const clearApplicationCpa = async () => {
  try {
    await SecureStoragePlugin.remove({ key: APP_CPA });
  } catch {
    // fail silently
  }
};

/**
 * Resolve cpa site url from location.
 * Falls back to dev env var when available.
 */
export const getCpaSiteUrl = (options: { hostnameOnly?: boolean } = {}) => {
  // Fallback to dev defined CPA in preview environments
  // NOTE: This should only be used in dev/preview envs
  const origin = config.dev.defaultSiteCpa || window?.location?.origin;
  const siteUrl = new URL(origin);

  // Only return CPA for web platforms with defined cpa subdomain
  if (!Capacitor.isNativePlatform() && siteUrl.hostname !== 'localhost') {
    return options.hostnameOnly ? siteUrl.hostname : siteUrl.origin;
  }
};

export const makeStateCpaFromV5Cpa = (
  v5Cpa: AuthorizedCpaUserCpaData
): CpaStateItem => ({
  name: v5Cpa.cpa_name,
  logo: v5Cpa.cpa_logo_url,
  custom_liscio_url: v5Cpa.company_url,
  cpa_uuid: v5Cpa.cpa_uuid,
});
