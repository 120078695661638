import React, { useCallback, useState } from 'react';

import { styled, Box, Stack } from '@liscio/ui';
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';

import { Participant, UploadFormType } from '@liscio/api';

import {
  MessageForm,
  AdditionalParticipantForm,
  AdditionalParticipantList,
} from './components';
import { generateMessagePostBodyForBottomForm } from './helpers';
import { BottomMessageForm } from '../../ThreadDetailsView';
import { AddAttachmentButton, RemoveFileFromMessageDialog } from 'components';
import { Overlay } from '@liscio/common';
import { makeFileId } from 'fetch-utils/documents/documentsCallsUtils';
import { useSendMessage } from 'fetch-utils/messages/messages-hooks';
import { FileInFormRow } from 'modules/forms/documents-form/components/UploadForm/components/ScannerAndInput/components/FilesInForm/components';
import UploadDocumentFormView from 'modules/forms/documents-form/UploadDocumentFormView';
import { UploadDocumentFormData } from 'modules/forms/documents-form/useUploadDocumentForm';
import removeDocIdAndFile from 'modules/messages/views/NewMessageView/components/NewMessageForm/helpers/removeDocIdAndFile';

const GridBox = styled(Box)({
  display: 'grid',
  gap: '20px',
  marginTop: '20px',
});
export interface BottomFormProps {
  draftId?: string;
  title: string;
  participants: Array<Participant>;
  numberOfRecipients: number;
  warningMessage?: string;
  isOptimisticValue?: boolean;
}

const DEFAULT_WARNING_MESSAGE =
  'The message has no active firm recipients. Please add another firm recipient.';

export const BottomForm: React.FC<BottomFormProps> = ({
  draftId,
  title,
  participants,
  numberOfRecipients,
  warningMessage,
  isOptimisticValue,
}) => {
  const [selectedArchiveDialogIndex, setSelectedArchiveDialogIndex] =
    useState(0);
  const [showArchiveDialog, setShowArchiveDialog] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);

  const { handleSubmit, getValues, reset, setValue, watch } = useFormContext();
  const docIds = watch('doc_ids');
  const files = watch('files');
  const participantsInForm = watch('participants');

  const shouldForceAdditionalParticipant =
    (warningMessage || numberOfRecipients === 0) &&
    participants?.length === participantsInForm?.length &&
    !isOptimisticValue;

  const { mutateAsync: sendMessage, isLoading: isMessageSending } =
    useSendMessage({
      onSuccess: () => {
        reset();
      },
    });

  const onSubmit: (data: BottomMessageForm) => void = (data) => {
    const { message, participants, threadId, doc_ids } = data;
    const postBody = generateMessagePostBodyForBottomForm({
      draftId: draftId || '',
      message,
      participants,
      threadId,
      title,
      doc_ids: doc_ids || [],
    });

    sendMessage(postBody);
  };

  const handleDeleteClick = (index: number) => {
    setShowArchiveDialog(true);
    setSelectedArchiveDialogIndex(index);
  };

  const handleUploadClick = () => {
    setShowUploadForm(true);
  };

  async function handleFileUpload(
    docIds?: UploadDocumentFormData['doc_ids'],
    uploadFormData?: UploadDocumentFormData
  ) {
    const { doc_ids, files } = getValues();
    if (uploadFormData) {
      setValue('files', [...files, ...uploadFormData.files!]);
    }

    setValue('doc_ids', [...doc_ids!, ...docIds!]);
  }

  const handleArchiveSuccess = useCallback(
    (id: number) => {
      const { newDocIds, newFiles } = removeDocIdAndFile(
        String(id),
        docIds!,
        files!
      );

      setValue('doc_ids', [...newDocIds!]);
      setValue('files', [...newFiles]);
    },
    [setValue, docIds, files]
  );

  return (
    <>
      {showUploadForm && (
        <Overlay open={showUploadForm}>
          <UploadDocumentFormView
            goBack={() => setShowUploadForm(false)}
            type={UploadFormType.MessageThread}
            onFileUpload={handleFileUpload}
          />
        </Overlay>
      )}
      <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
        <AddAttachmentButton onClick={handleUploadClick} />
        <GridBox>
          {files?.map((file: File, i: number) => (
            <FileInFormRow
              key={makeFileId(file)}
              file={file}
              onDeleteClick={() => handleDeleteClick(i)}
            />
          ))}
        </GridBox>
        <Stack pt={3} pl={2} pr={2}>
          <AdditionalParticipantList participants={participants} />
          {!shouldForceAdditionalParticipant && (
            <MessageForm isLoading={isMessageSending} />
          )}
          {shouldForceAdditionalParticipant && (
            <AdditionalParticipantForm
              warningMessage={warningMessage || DEFAULT_WARNING_MESSAGE}
            />
          )}
        </Stack>
      </form>
      <RemoveFileFromMessageDialog
        documentId={docIds[selectedArchiveDialogIndex]}
        messageId={draftId || ''}
        open={showArchiveDialog}
        file={files[selectedArchiveDialogIndex]}
        onSuccess={handleArchiveSuccess}
        closeFunction={() => setShowArchiveDialog(false)}
      />
    </>
  );
};

export default BottomForm;
