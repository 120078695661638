import { Stack, styled, CircularProgress } from '@liscio/ui';

const Wrapper = styled(Stack)(({ theme }) => ({
  height: theme.spacing(30),
  width: '100%',
}));

export const LoadingPlaceholder = () => (
  <Wrapper alignItems="center" justifyContent="center">
    <CircularProgress color="inherit" />
  </Wrapper>
);

export default LoadingPlaceholder;
