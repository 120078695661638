import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Box, Button, Link, Stack, styled, Typography } from '@liscio/ui';

import { formSchema } from './validationSchema';
import { AuthTextField } from '../FormFields';

export interface MfaFormInterface {
  mfaCode: string;
}

export interface MfaCodeFormProps {
  isLoading?: boolean;
  onSubmit: (data: MfaFormInterface) => void;
  backToLogin: () => void;
}

const FormStyled = styled('form')(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const MfaCodeForm = ({
  isLoading,
  onSubmit,
  backToLogin,
}: MfaCodeFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<MfaFormInterface>({
    resolver: yupResolver(formSchema),
  });
  return (
    <FormStyled onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h3" textAlign="center" mb={4}>
        Enter Passcode
      </Typography>
      <Typography variant="body1" textAlign="center" mb={4}>
        Enter the code on your mobile passcode application
      </Typography>
      <Stack gap={2}>
        <AuthTextField
          {...register('mfaCode')}
          fullWidth
          placeholder="Enter Passcode"
          error={Boolean(errors?.mfaCode)}
          helperText={errors?.mfaCode?.message as string}
        />
        <Button
          variant="contained"
          type="submit"
          disabled={isLoading}
          size="large"
        >
          Verify Code
        </Button>
      </Stack>
      <Box mt={4}>
        <Typography variant="body1" textAlign="center" mb={4}>
          Back to{' '}
          <Link sx={{ cursor: 'pointer' }} onClick={backToLogin}>
            Login
          </Link>
        </Typography>
      </Box>
    </FormStyled>
  );
};
