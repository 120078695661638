import { initApiClient, getCommonInterceptors } from '@liscio/common';

import { config } from 'config';
import { LOGOUT_REDIRECT_URL } from 'modules/authenticate/route-constants';

const apiClient = initApiClient({
  baseURL: config.api.url,
  ...getCommonInterceptors(LOGOUT_REDIRECT_URL),
});
apiClient.client.defaults.headers['x-app-version'] = config.version;
apiClient.client.defaults.headers['x-app-platform'] = config.platform;
apiClient.client.defaults.headers['x-app-name'] = config.appIdentifier;

export default apiClient;
