export const REQUESTS_PROTECTED_DOMAIN = 'elastic-search-requests';
export const REQUESTS_PROTECTED_DOMAIN_URL = `/${REQUESTS_PROTECTED_DOMAIN}/*`;
export const REQUESTS_INDEX = 'requests';
export const REQUESTS_INDEX_URL = `/${REQUESTS_INDEX}/*`;
export const REQUESTS_BUILDER = `${REQUESTS_INDEX}/builder`;
export const REQUESTS_BUILDER_URL = `/${REQUESTS_BUILDER}/*`;
export const REQUESTS_DISPATCH = `${REQUESTS_INDEX}/dispatch`;
export const REQUESTS_DISPATCH_URL = `/${REQUESTS_DISPATCH}/*`;

export const REQUESTS_PAGE_TITLES = {
  index: 'Requests',
  builder: 'Request Builder',
};
