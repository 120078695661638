import { useCallback, useMemo } from 'react';

import { Capacitor } from '@capacitor/core';
import { useQueryClient } from '@tanstack/react-query';

import { useCpaStore } from '@liscio/common';

import { saveApplicationCpa } from './cpa-utils';
import { CpaStateItem } from './cpa.types';
import { useSwitchCpaMutation } from 'fetch-utils/users/auth-hooks';
import { useHomeData } from 'fetch-utils/users/user-hooks';

/**
 * Convenience hook for managing user Cpa(s)
 * NOTE: This hook should only be used in authorized views since it
 * utilizes home data which requires an auth token
 * TODO: Handle parsing cpa id from query parameters to select cpa
 */
export const useCpaV3 = () => {
  const { isLoading, isInitialized, sessionCpa, appCpa, setAppCpa } =
    useCpaStore();
  const { data: homeData, isLoading: homeDataIsloading } = useHomeData();
  const {
    mutateAsync: switchCpaMutation,
    isLoading: isSwitchingCpa,
    error: switchCpaError,
  } = useSwitchCpaMutation();
  const queryClient = useQueryClient();

  // List of user related cpas
  const cpas = useMemo(() => {
    const cpaList: CpaStateItem[] = [];

    // Handle user session cpa
    const userData = homeData?.data;
    if (
      userData?.company_url &&
      userData?.cpa_id &&
      userData?.cpa_name &&
      userData?.cpa_logo
    ) {
      // Add to cpa list
      cpaList.push({
        id: userData.cpa_id,
        name: userData.cpa_name,
        logo: userData.cpa_logo,
        custom_liscio_url: userData.company_url,
      });
    }

    // Add home cpa_data array
    if (homeData?.cpa_data) {
      const newCpas = homeData.cpa_data.filter(
        (itm) => !cpaList.find((existingCpa) => existingCpa.id === itm.id)
      );
      cpaList.push(...newCpas);
    }

    return cpaList;
  }, [homeData]);

  // Select Application Cpa
  const selectCpa = useCallback(
    async (cpa: CpaStateItem) => {
      // Only switch cpa in backend if different from existing session cpa
      if (!sessionCpa || sessionCpa.id !== cpa.id) {
        await switchCpaMutation({ cpa_id: cpa.id! });
      }
      setAppCpa(cpa);
      await saveApplicationCpa(cpa);
    },
    [sessionCpa, setAppCpa, switchCpaMutation]
  );

  const switchCpa = useCallback(
    async (cpaUrl: string) => {
      const selectedCpa = cpas.find((cpa) => cpa.custom_liscio_url === cpaUrl);

      if (selectedCpa) {
        try {
          await switchCpaMutation({ cpa_id: selectedCpa.id! });

          const newCpa = {
            id: selectedCpa.id,
            name: selectedCpa.name,
            logo: selectedCpa.logo,
            custom_liscio_url: selectedCpa.custom_liscio_url,
          };
          setAppCpa(newCpa);
          await saveApplicationCpa(newCpa);

          if (Capacitor.isNativePlatform()) {
            // On native platforms we refresh the user session
            queryClient.invalidateQueries();
          } else {
            // On web, we redirect
            window.location.href = newCpa.custom_liscio_url || '';
          }
        } catch {}
      }
    },
    [switchCpaMutation, setAppCpa, cpas, queryClient]
  );

  return {
    cpa: appCpa,
    cpas,
    selectCpa,
    isLoading:
      !isInitialized || isLoading || homeDataIsloading || isSwitchingCpa,
    switchCpa,
    error: switchCpaError,
  };
};
