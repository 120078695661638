import { ThemeExtension } from '@liscio/ui';

/**
 * Sometimes for firm for user experience consistency we will want to mimic the desktop theme in refactor
 **/
export const refactorComponents: ThemeExtension = (theme) => ({
  components: {
    MuiTab: {
      styleOverrides: {
        root: () => ({
          color: theme.palette.text.secondary,
          textTransform: 'none',
          fontSize: '1.12rem',
          padding: '1rem',
          '&.Mui-selected': {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
  },
});
