export const ADMIN_DOMAIN = 'admin';
export const ADMIN_DOMAIN_URL = `/${ADMIN_DOMAIN}/*`;

export const ADMIN_DOMAIN_PATHS = {
  billing: '/billing',
  users: '/users',
  updateUser: 'update',
  templates: '/templates/:tab',
};

export const ADMIN_DOMAIN_PAGE_TITLES = {
  billing: 'Billing | Admin',
  users: 'Users | Admin',
  updateUser: 'Update | Users | Admin',
  templates: 'Templates | Admin',
};
