import { ThemeProvider } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { useViewportSize, createTheme } from '@liscio/ui';

import { desktopComponents } from 'theme/desktop-theme';
import { refactorComponents } from 'theme/refactor-theme';

/**
 * ConditionalThemeProvider
 */
export const ConditionalThemeProvider: React.FC = () => {
  const { isDesktop } = useViewportSize();
  const theme = createTheme({
    extensions: [desktopComponents, refactorComponents],
  });

  if (isDesktop) {
    return (
      <ThemeProvider theme={theme}>
        <Outlet />
      </ThemeProvider>
    );
  }
  return <Outlet />;
};
