import { useRef as Be, useEffect as z, useState as Xe, useCallback as dt } from "react";
const In = (r) => {
  if (r.length < 12)
    throw new Error("Password must contain at least 12 characters");
  if (!/[A-Z]/.test(r))
    throw new Error(
      "Password must contain at least 1 uppercase character  (A..Z)"
    );
  if (!/\d/.test(r))
    throw new Error(
      "Password must contain at least 1 number character  (0..9)"
    );
  if (!/[!@#$%^&*()_+\-={}|[\];:,./<>?~`]/.test(r))
    throw new Error(
      "Password must contain at least 1 special character  (!@#$%^&*()_+-={}|[];:,./<>?~`)"
    );
  const a = r.match(
    /[^A-Za-z\d!@#$%^&*()_+\-={}|[\];:,./<>?~`]/g
  );
  if (a != null && a.length)
    throw new Error(
      `Password contains invalid characters ${a.map((t) => `(${t})`).join(", ")}`
    );
  return !0;
}, Te = "body-wrapper", Nn = (r = Te) => {
  var a;
  return ((a = document.getElementById(r)) == null ? void 0 : a.scrollTop) || 0;
}, An = (r) => {
  const a = document.getElementById(Te);
  a && (a.scrollTop = r);
}, Hn = (r, a = Te, { includeElementHeight: t = !0, scrollOffset: n = 0 } = {}) => {
  const e = document.getElementById(a);
  if (e && e.scrollTo && r) {
    const o = r.offsetTop, i = t ? r.offsetHeight : 0, u = o + i + n;
    e.scrollTo({
      top: u,
      behavior: "smooth"
    });
  }
};
function Ln(r) {
  r.preventDefault(), r.stopPropagation(), r.nativeEvent.stopImmediatePropagation();
}
const Rn = (r = "localStorage") => {
  const a = r === "sessionStorage" ? sessionStorage : localStorage;
  return {
    setItem: (o, i) => {
      a.setItem(o, JSON.stringify(i));
    },
    getItem: (o) => {
      try {
        const i = a.getItem(o);
        if (i)
          return JSON.parse(i);
      } catch {
      }
    },
    removeItem: (o) => {
      a.removeItem(o);
    },
    clear: a.clear
  };
}, vt = (r, a) => a.sizeIdx < 2 ? 0 : a.sizeIdx === 2 ? r < 10 ? 1 : 0 : 1, qn = (r, {
  precision: a = vt,
  sizeTerms: t = ["bytes", "kb", "mb", "gb", "tb", "pb"],
  threshold: n = 1e3
} = {}) => {
  if (r === 0)
    return "";
  const e = Math.min(
    Math.floor(Math.log(r) / Math.log(n)),
    t.length - 1
  ), o = r / Math.pow(n, e), i = t[e], u = typeof a == "number" ? a : Array.isArray(a) ? a[e] || 0 : a(o, { threshold: n, bytes: r, sizeIdx: e, unit: i });
  return `${parseFloat(o.toFixed(u))} ${i}`;
}, mt = "Something went wrong during submission.", Sn = (r, a = mt) => {
  var t, n, e, o, i, u, s, l;
  if (r)
    return Array.isArray((n = (t = r == null ? void 0 : r.response) == null ? void 0 : t.data) == null ? void 0 : n.errors) ? (u = (i = (o = (e = r == null ? void 0 : r.response) == null ? void 0 : e.data) == null ? void 0 : o.errors) == null ? void 0 : i[0]) == null ? void 0 : u.message : typeof (r == null ? void 0 : r.message) == "string" ? r.message : typeof (r == null ? void 0 : r.error) == "string" ? r.error : typeof ((l = (s = r == null ? void 0 : r.errors) == null ? void 0 : s[0]) == null ? void 0 : l.message) == "string" ? r.errors[0].message : a;
};
function ht(r) {
  return r ? r.charAt(0).toUpperCase() + r.slice(1) : "";
}
function Fn(r) {
  return r ? ht(r).slice(0, r.length - 1) : "";
}
const $n = (r = "") => r.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;"), Qn = (r) => r.replace(/(\r\n|\r|\n)/g, "<br/>"), Bn = (r) => r.split(/[\s+]/).map(
  (t) => `${(t[0] || "").toUpperCase()}${t.slice(1).toLowerCase()}`
).join("");
function Xn(r) {
  return typeof r != "number" ? "" : `${(r / 1024).toFixed(2)} KB`;
}
const jn = (r) => {
  const a = r.split("."), t = a.length > 1 ? a.pop() : void 0, n = a.join(".");
  return {
    fileName: r,
    baseName: n,
    extension: t
  };
}, wt = (r, a = "") => {
  const t = Number(r);
  return !Number.isFinite(t) || r === "" || r === null || r === void 0 ? a : t.toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });
}, Gn = (r, a) => {
  const n = parseInt(r) / 100;
  return wt(n, a);
};
function $(r) {
  "@babel/helpers - typeof";
  return $ = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(a) {
    return typeof a;
  } : function(a) {
    return a && typeof Symbol == "function" && a.constructor === Symbol && a !== Symbol.prototype ? "symbol" : typeof a;
  }, $(r);
}
function A(r) {
  if (r === null || r === !0 || r === !1)
    return NaN;
  var a = Number(r);
  return isNaN(a) ? a : a < 0 ? Math.ceil(a) : Math.floor(a);
}
function D(r, a) {
  if (a.length < r)
    throw new TypeError(r + " argument" + (r > 1 ? "s" : "") + " required, but only " + a.length + " present");
}
function y(r) {
  D(1, arguments);
  var a = Object.prototype.toString.call(r);
  return r instanceof Date || $(r) === "object" && a === "[object Date]" ? new Date(r.getTime()) : typeof r == "number" || a === "[object Number]" ? new Date(r) : ((typeof r == "string" || a === "[object String]") && typeof console < "u" && (console.warn("Starting with v2.0.0-beta.1 date-fns doesn't accept strings as date arguments. Please use `parseISO` to parse strings. See: https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#string-arguments"), console.warn(new Error().stack)), /* @__PURE__ */ new Date(NaN));
}
function gt(r, a) {
  D(2, arguments);
  var t = y(r).getTime(), n = A(a);
  return new Date(t + n);
}
var pt = {};
function K() {
  return pt;
}
function fe(r) {
  var a = new Date(Date.UTC(r.getFullYear(), r.getMonth(), r.getDate(), r.getHours(), r.getMinutes(), r.getSeconds(), r.getMilliseconds()));
  return a.setUTCFullYear(r.getFullYear()), r.getTime() - a.getTime();
}
function le(r, a) {
  D(2, arguments);
  var t = y(r), n = y(a), e = t.getTime() - n.getTime();
  return e < 0 ? -1 : e > 0 ? 1 : e;
}
var yt = 6e4, bt = 36e5, Tt = 1e3;
function Dt(r) {
  return D(1, arguments), r instanceof Date || $(r) === "object" && Object.prototype.toString.call(r) === "[object Date]";
}
function Mt(r) {
  if (D(1, arguments), !Dt(r) && typeof r != "number")
    return !1;
  var a = y(r);
  return !isNaN(Number(a));
}
function Pt(r, a) {
  D(2, arguments);
  var t = y(r), n = y(a), e = t.getFullYear() - n.getFullYear(), o = t.getMonth() - n.getMonth();
  return e * 12 + o;
}
function Ct(r, a) {
  return D(2, arguments), y(r).getTime() - y(a).getTime();
}
var Ne = {
  ceil: Math.ceil,
  round: Math.round,
  floor: Math.floor,
  trunc: function(a) {
    return a < 0 ? Math.ceil(a) : Math.floor(a);
  }
  // Math.trunc is not supported by IE
}, xt = "trunc";
function Ot(r) {
  return r ? Ne[r] : Ne[xt];
}
function kt(r) {
  D(1, arguments);
  var a = y(r);
  return a.setHours(23, 59, 59, 999), a;
}
function Ut(r) {
  D(1, arguments);
  var a = y(r), t = a.getMonth();
  return a.setFullYear(a.getFullYear(), t + 1, 0), a.setHours(23, 59, 59, 999), a;
}
function Et(r) {
  D(1, arguments);
  var a = y(r);
  return kt(a).getTime() === Ut(a).getTime();
}
function Yt(r, a) {
  D(2, arguments);
  var t = y(r), n = y(a), e = le(t, n), o = Math.abs(Pt(t, n)), i;
  if (o < 1)
    i = 0;
  else {
    t.getMonth() === 1 && t.getDate() > 27 && t.setDate(30), t.setMonth(t.getMonth() - e * o);
    var u = le(t, n) === -e;
    Et(y(r)) && o === 1 && le(r, n) === 1 && (u = !1), i = e * (o - Number(u));
  }
  return i === 0 ? 0 : i;
}
function _t(r, a, t) {
  D(2, arguments);
  var n = Ct(r, a) / 1e3;
  return Ot(void 0)(n);
}
function je(r, a) {
  D(2, arguments);
  var t = A(a);
  return gt(r, -t);
}
var Wt = 864e5;
function It(r) {
  D(1, arguments);
  var a = y(r), t = a.getTime();
  a.setUTCMonth(0, 1), a.setUTCHours(0, 0, 0, 0);
  var n = a.getTime(), e = t - n;
  return Math.floor(e / Wt) + 1;
}
function ee(r) {
  D(1, arguments);
  var a = 1, t = y(r), n = t.getUTCDay(), e = (n < a ? 7 : 0) + n - a;
  return t.setUTCDate(t.getUTCDate() - e), t.setUTCHours(0, 0, 0, 0), t;
}
function Ge(r) {
  D(1, arguments);
  var a = y(r), t = a.getUTCFullYear(), n = /* @__PURE__ */ new Date(0);
  n.setUTCFullYear(t + 1, 0, 4), n.setUTCHours(0, 0, 0, 0);
  var e = ee(n), o = /* @__PURE__ */ new Date(0);
  o.setUTCFullYear(t, 0, 4), o.setUTCHours(0, 0, 0, 0);
  var i = ee(o);
  return a.getTime() >= e.getTime() ? t + 1 : a.getTime() >= i.getTime() ? t : t - 1;
}
function Nt(r) {
  D(1, arguments);
  var a = Ge(r), t = /* @__PURE__ */ new Date(0);
  t.setUTCFullYear(a, 0, 4), t.setUTCHours(0, 0, 0, 0);
  var n = ee(t);
  return n;
}
var At = 6048e5;
function ze(r) {
  D(1, arguments);
  var a = y(r), t = ee(a).getTime() - Nt(a).getTime();
  return Math.round(t / At) + 1;
}
function V(r, a) {
  var t, n, e, o, i, u, s, l;
  D(1, arguments);
  var v = K(), d = A((t = (n = (e = (o = a == null ? void 0 : a.weekStartsOn) !== null && o !== void 0 ? o : a == null || (i = a.locale) === null || i === void 0 || (u = i.options) === null || u === void 0 ? void 0 : u.weekStartsOn) !== null && e !== void 0 ? e : v.weekStartsOn) !== null && n !== void 0 ? n : (s = v.locale) === null || s === void 0 || (l = s.options) === null || l === void 0 ? void 0 : l.weekStartsOn) !== null && t !== void 0 ? t : 0);
  if (!(d >= 0 && d <= 6))
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  var M = y(r), m = M.getUTCDay(), P = (m < d ? 7 : 0) + m - d;
  return M.setUTCDate(M.getUTCDate() - P), M.setUTCHours(0, 0, 0, 0), M;
}
function De(r, a) {
  var t, n, e, o, i, u, s, l;
  D(1, arguments);
  var v = y(r), d = v.getUTCFullYear(), M = K(), m = A((t = (n = (e = (o = a == null ? void 0 : a.firstWeekContainsDate) !== null && o !== void 0 ? o : a == null || (i = a.locale) === null || i === void 0 || (u = i.options) === null || u === void 0 ? void 0 : u.firstWeekContainsDate) !== null && e !== void 0 ? e : M.firstWeekContainsDate) !== null && n !== void 0 ? n : (s = M.locale) === null || s === void 0 || (l = s.options) === null || l === void 0 ? void 0 : l.firstWeekContainsDate) !== null && t !== void 0 ? t : 1);
  if (!(m >= 1 && m <= 7))
    throw new RangeError("firstWeekContainsDate must be between 1 and 7 inclusively");
  var P = /* @__PURE__ */ new Date(0);
  P.setUTCFullYear(d + 1, 0, m), P.setUTCHours(0, 0, 0, 0);
  var I = V(P, a), W = /* @__PURE__ */ new Date(0);
  W.setUTCFullYear(d, 0, m), W.setUTCHours(0, 0, 0, 0);
  var R = V(W, a);
  return v.getTime() >= I.getTime() ? d + 1 : v.getTime() >= R.getTime() ? d : d - 1;
}
function Ht(r, a) {
  var t, n, e, o, i, u, s, l;
  D(1, arguments);
  var v = K(), d = A((t = (n = (e = (o = a == null ? void 0 : a.firstWeekContainsDate) !== null && o !== void 0 ? o : a == null || (i = a.locale) === null || i === void 0 || (u = i.options) === null || u === void 0 ? void 0 : u.firstWeekContainsDate) !== null && e !== void 0 ? e : v.firstWeekContainsDate) !== null && n !== void 0 ? n : (s = v.locale) === null || s === void 0 || (l = s.options) === null || l === void 0 ? void 0 : l.firstWeekContainsDate) !== null && t !== void 0 ? t : 1), M = De(r, a), m = /* @__PURE__ */ new Date(0);
  m.setUTCFullYear(M, 0, d), m.setUTCHours(0, 0, 0, 0);
  var P = V(m, a);
  return P;
}
var Lt = 6048e5;
function Ve(r, a) {
  D(1, arguments);
  var t = y(r), n = V(t, a).getTime() - Ht(t, a).getTime();
  return Math.round(n / Lt) + 1;
}
function T(r, a) {
  for (var t = r < 0 ? "-" : "", n = Math.abs(r).toString(); n.length < a; )
    n = "0" + n;
  return t + n;
}
var X = {
  // Year
  y: function(a, t) {
    var n = a.getUTCFullYear(), e = n > 0 ? n : 1 - n;
    return T(t === "yy" ? e % 100 : e, t.length);
  },
  // Month
  M: function(a, t) {
    var n = a.getUTCMonth();
    return t === "M" ? String(n + 1) : T(n + 1, 2);
  },
  // Day of the month
  d: function(a, t) {
    return T(a.getUTCDate(), t.length);
  },
  // AM or PM
  a: function(a, t) {
    var n = a.getUTCHours() / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return n.toUpperCase();
      case "aaa":
        return n;
      case "aaaaa":
        return n[0];
      case "aaaa":
      default:
        return n === "am" ? "a.m." : "p.m.";
    }
  },
  // Hour [1-12]
  h: function(a, t) {
    return T(a.getUTCHours() % 12 || 12, t.length);
  },
  // Hour [0-23]
  H: function(a, t) {
    return T(a.getUTCHours(), t.length);
  },
  // Minute
  m: function(a, t) {
    return T(a.getUTCMinutes(), t.length);
  },
  // Second
  s: function(a, t) {
    return T(a.getUTCSeconds(), t.length);
  },
  // Fraction of second
  S: function(a, t) {
    var n = t.length, e = a.getUTCMilliseconds(), o = Math.floor(e * Math.pow(10, n - 3));
    return T(o, t.length);
  }
}, J = {
  am: "am",
  pm: "pm",
  midnight: "midnight",
  noon: "noon",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  night: "night"
}, Rt = {
  // Era
  G: function(a, t, n) {
    var e = a.getUTCFullYear() > 0 ? 1 : 0;
    switch (t) {
      case "G":
      case "GG":
      case "GGG":
        return n.era(e, {
          width: "abbreviated"
        });
      case "GGGGG":
        return n.era(e, {
          width: "narrow"
        });
      case "GGGG":
      default:
        return n.era(e, {
          width: "wide"
        });
    }
  },
  // Year
  y: function(a, t, n) {
    if (t === "yo") {
      var e = a.getUTCFullYear(), o = e > 0 ? e : 1 - e;
      return n.ordinalNumber(o, {
        unit: "year"
      });
    }
    return X.y(a, t);
  },
  // Local week-numbering year
  Y: function(a, t, n, e) {
    var o = De(a, e), i = o > 0 ? o : 1 - o;
    if (t === "YY") {
      var u = i % 100;
      return T(u, 2);
    }
    return t === "Yo" ? n.ordinalNumber(i, {
      unit: "year"
    }) : T(i, t.length);
  },
  // ISO week-numbering year
  R: function(a, t) {
    var n = Ge(a);
    return T(n, t.length);
  },
  // Extended year. This is a single number designating the year of this calendar system.
  // The main difference between `y` and `u` localizers are B.C. years:
  // | Year | `y` | `u` |
  // |------|-----|-----|
  // | AC 1 |   1 |   1 |
  // | BC 1 |   1 |   0 |
  // | BC 2 |   2 |  -1 |
  // Also `yy` always returns the last two digits of a year,
  // while `uu` pads single digit years to 2 characters and returns other years unchanged.
  u: function(a, t) {
    var n = a.getUTCFullYear();
    return T(n, t.length);
  },
  // Quarter
  Q: function(a, t, n) {
    var e = Math.ceil((a.getUTCMonth() + 1) / 3);
    switch (t) {
      case "Q":
        return String(e);
      case "QQ":
        return T(e, 2);
      case "Qo":
        return n.ordinalNumber(e, {
          unit: "quarter"
        });
      case "QQQ":
        return n.quarter(e, {
          width: "abbreviated",
          context: "formatting"
        });
      case "QQQQQ":
        return n.quarter(e, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return n.quarter(e, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone quarter
  q: function(a, t, n) {
    var e = Math.ceil((a.getUTCMonth() + 1) / 3);
    switch (t) {
      case "q":
        return String(e);
      case "qq":
        return T(e, 2);
      case "qo":
        return n.ordinalNumber(e, {
          unit: "quarter"
        });
      case "qqq":
        return n.quarter(e, {
          width: "abbreviated",
          context: "standalone"
        });
      case "qqqqq":
        return n.quarter(e, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return n.quarter(e, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Month
  M: function(a, t, n) {
    var e = a.getUTCMonth();
    switch (t) {
      case "M":
      case "MM":
        return X.M(a, t);
      case "Mo":
        return n.ordinalNumber(e + 1, {
          unit: "month"
        });
      case "MMM":
        return n.month(e, {
          width: "abbreviated",
          context: "formatting"
        });
      case "MMMMM":
        return n.month(e, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return n.month(e, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone month
  L: function(a, t, n) {
    var e = a.getUTCMonth();
    switch (t) {
      case "L":
        return String(e + 1);
      case "LL":
        return T(e + 1, 2);
      case "Lo":
        return n.ordinalNumber(e + 1, {
          unit: "month"
        });
      case "LLL":
        return n.month(e, {
          width: "abbreviated",
          context: "standalone"
        });
      case "LLLLL":
        return n.month(e, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return n.month(e, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Local week of year
  w: function(a, t, n, e) {
    var o = Ve(a, e);
    return t === "wo" ? n.ordinalNumber(o, {
      unit: "week"
    }) : T(o, t.length);
  },
  // ISO week of year
  I: function(a, t, n) {
    var e = ze(a);
    return t === "Io" ? n.ordinalNumber(e, {
      unit: "week"
    }) : T(e, t.length);
  },
  // Day of the month
  d: function(a, t, n) {
    return t === "do" ? n.ordinalNumber(a.getUTCDate(), {
      unit: "date"
    }) : X.d(a, t);
  },
  // Day of year
  D: function(a, t, n) {
    var e = It(a);
    return t === "Do" ? n.ordinalNumber(e, {
      unit: "dayOfYear"
    }) : T(e, t.length);
  },
  // Day of week
  E: function(a, t, n) {
    var e = a.getUTCDay();
    switch (t) {
      case "E":
      case "EE":
      case "EEE":
        return n.day(e, {
          width: "abbreviated",
          context: "formatting"
        });
      case "EEEEE":
        return n.day(e, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return n.day(e, {
          width: "short",
          context: "formatting"
        });
      case "EEEE":
      default:
        return n.day(e, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Local day of week
  e: function(a, t, n, e) {
    var o = a.getUTCDay(), i = (o - e.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "e":
        return String(i);
      case "ee":
        return T(i, 2);
      case "eo":
        return n.ordinalNumber(i, {
          unit: "day"
        });
      case "eee":
        return n.day(o, {
          width: "abbreviated",
          context: "formatting"
        });
      case "eeeee":
        return n.day(o, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return n.day(o, {
          width: "short",
          context: "formatting"
        });
      case "eeee":
      default:
        return n.day(o, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone local day of week
  c: function(a, t, n, e) {
    var o = a.getUTCDay(), i = (o - e.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "c":
        return String(i);
      case "cc":
        return T(i, t.length);
      case "co":
        return n.ordinalNumber(i, {
          unit: "day"
        });
      case "ccc":
        return n.day(o, {
          width: "abbreviated",
          context: "standalone"
        });
      case "ccccc":
        return n.day(o, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return n.day(o, {
          width: "short",
          context: "standalone"
        });
      case "cccc":
      default:
        return n.day(o, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // ISO day of week
  i: function(a, t, n) {
    var e = a.getUTCDay(), o = e === 0 ? 7 : e;
    switch (t) {
      case "i":
        return String(o);
      case "ii":
        return T(o, t.length);
      case "io":
        return n.ordinalNumber(o, {
          unit: "day"
        });
      case "iii":
        return n.day(e, {
          width: "abbreviated",
          context: "formatting"
        });
      case "iiiii":
        return n.day(e, {
          width: "narrow",
          context: "formatting"
        });
      case "iiiiii":
        return n.day(e, {
          width: "short",
          context: "formatting"
        });
      case "iiii":
      default:
        return n.day(e, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM or PM
  a: function(a, t, n) {
    var e = a.getUTCHours(), o = e / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return n.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        });
      case "aaa":
        return n.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "aaaaa":
        return n.dayPeriod(o, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return n.dayPeriod(o, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM, PM, midnight, noon
  b: function(a, t, n) {
    var e = a.getUTCHours(), o;
    switch (e === 12 ? o = J.noon : e === 0 ? o = J.midnight : o = e / 12 >= 1 ? "pm" : "am", t) {
      case "b":
      case "bb":
        return n.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        });
      case "bbb":
        return n.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "bbbbb":
        return n.dayPeriod(o, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return n.dayPeriod(o, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // in the morning, in the afternoon, in the evening, at night
  B: function(a, t, n) {
    var e = a.getUTCHours(), o;
    switch (e >= 17 ? o = J.evening : e >= 12 ? o = J.afternoon : e >= 4 ? o = J.morning : o = J.night, t) {
      case "B":
      case "BB":
      case "BBB":
        return n.dayPeriod(o, {
          width: "abbreviated",
          context: "formatting"
        });
      case "BBBBB":
        return n.dayPeriod(o, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return n.dayPeriod(o, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Hour [1-12]
  h: function(a, t, n) {
    if (t === "ho") {
      var e = a.getUTCHours() % 12;
      return e === 0 && (e = 12), n.ordinalNumber(e, {
        unit: "hour"
      });
    }
    return X.h(a, t);
  },
  // Hour [0-23]
  H: function(a, t, n) {
    return t === "Ho" ? n.ordinalNumber(a.getUTCHours(), {
      unit: "hour"
    }) : X.H(a, t);
  },
  // Hour [0-11]
  K: function(a, t, n) {
    var e = a.getUTCHours() % 12;
    return t === "Ko" ? n.ordinalNumber(e, {
      unit: "hour"
    }) : T(e, t.length);
  },
  // Hour [1-24]
  k: function(a, t, n) {
    var e = a.getUTCHours();
    return e === 0 && (e = 24), t === "ko" ? n.ordinalNumber(e, {
      unit: "hour"
    }) : T(e, t.length);
  },
  // Minute
  m: function(a, t, n) {
    return t === "mo" ? n.ordinalNumber(a.getUTCMinutes(), {
      unit: "minute"
    }) : X.m(a, t);
  },
  // Second
  s: function(a, t, n) {
    return t === "so" ? n.ordinalNumber(a.getUTCSeconds(), {
      unit: "second"
    }) : X.s(a, t);
  },
  // Fraction of second
  S: function(a, t) {
    return X.S(a, t);
  },
  // Timezone (ISO-8601. If offset is 0, output is always `'Z'`)
  X: function(a, t, n, e) {
    var o = e._originalDate || a, i = o.getTimezoneOffset();
    if (i === 0)
      return "Z";
    switch (t) {
      case "X":
        return He(i);
      case "XXXX":
      case "XX":
        return G(i);
      case "XXXXX":
      case "XXX":
      default:
        return G(i, ":");
    }
  },
  // Timezone (ISO-8601. If offset is 0, output is `'+00:00'` or equivalent)
  x: function(a, t, n, e) {
    var o = e._originalDate || a, i = o.getTimezoneOffset();
    switch (t) {
      case "x":
        return He(i);
      case "xxxx":
      case "xx":
        return G(i);
      case "xxxxx":
      case "xxx":
      default:
        return G(i, ":");
    }
  },
  // Timezone (GMT)
  O: function(a, t, n, e) {
    var o = e._originalDate || a, i = o.getTimezoneOffset();
    switch (t) {
      case "O":
      case "OO":
      case "OOO":
        return "GMT" + Ae(i, ":");
      case "OOOO":
      default:
        return "GMT" + G(i, ":");
    }
  },
  // Timezone (specific non-location)
  z: function(a, t, n, e) {
    var o = e._originalDate || a, i = o.getTimezoneOffset();
    switch (t) {
      case "z":
      case "zz":
      case "zzz":
        return "GMT" + Ae(i, ":");
      case "zzzz":
      default:
        return "GMT" + G(i, ":");
    }
  },
  // Seconds timestamp
  t: function(a, t, n, e) {
    var o = e._originalDate || a, i = Math.floor(o.getTime() / 1e3);
    return T(i, t.length);
  },
  // Milliseconds timestamp
  T: function(a, t, n, e) {
    var o = e._originalDate || a, i = o.getTime();
    return T(i, t.length);
  }
};
function Ae(r, a) {
  var t = r > 0 ? "-" : "+", n = Math.abs(r), e = Math.floor(n / 60), o = n % 60;
  if (o === 0)
    return t + String(e);
  var i = a;
  return t + String(e) + i + T(o, 2);
}
function He(r, a) {
  if (r % 60 === 0) {
    var t = r > 0 ? "-" : "+";
    return t + T(Math.abs(r) / 60, 2);
  }
  return G(r, a);
}
function G(r, a) {
  var t = a || "", n = r > 0 ? "-" : "+", e = Math.abs(r), o = T(Math.floor(e / 60), 2), i = T(e % 60, 2);
  return n + o + t + i;
}
var Le = function(a, t) {
  switch (a) {
    case "P":
      return t.date({
        width: "short"
      });
    case "PP":
      return t.date({
        width: "medium"
      });
    case "PPP":
      return t.date({
        width: "long"
      });
    case "PPPP":
    default:
      return t.date({
        width: "full"
      });
  }
}, Ke = function(a, t) {
  switch (a) {
    case "p":
      return t.time({
        width: "short"
      });
    case "pp":
      return t.time({
        width: "medium"
      });
    case "ppp":
      return t.time({
        width: "long"
      });
    case "pppp":
    default:
      return t.time({
        width: "full"
      });
  }
}, qt = function(a, t) {
  var n = a.match(/(P+)(p+)?/) || [], e = n[1], o = n[2];
  if (!o)
    return Le(a, t);
  var i;
  switch (e) {
    case "P":
      i = t.dateTime({
        width: "short"
      });
      break;
    case "PP":
      i = t.dateTime({
        width: "medium"
      });
      break;
    case "PPP":
      i = t.dateTime({
        width: "long"
      });
      break;
    case "PPPP":
    default:
      i = t.dateTime({
        width: "full"
      });
      break;
  }
  return i.replace("{{date}}", Le(e, t)).replace("{{time}}", Ke(o, t));
}, ye = {
  p: Ke,
  P: qt
}, St = ["D", "DD"], Ft = ["YY", "YYYY"];
function Je(r) {
  return St.indexOf(r) !== -1;
}
function Ze(r) {
  return Ft.indexOf(r) !== -1;
}
function de(r, a, t) {
  if (r === "YYYY")
    throw new RangeError("Use `yyyy` instead of `YYYY` (in `".concat(a, "`) for formatting years to the input `").concat(t, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
  if (r === "YY")
    throw new RangeError("Use `yy` instead of `YY` (in `".concat(a, "`) for formatting years to the input `").concat(t, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
  if (r === "D")
    throw new RangeError("Use `d` instead of `D` (in `".concat(a, "`) for formatting days of the month to the input `").concat(t, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
  if (r === "DD")
    throw new RangeError("Use `dd` instead of `DD` (in `".concat(a, "`) for formatting days of the month to the input `").concat(t, "`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md"));
}
var $t = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "1 second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "1 hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "1 day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "1 year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years"
  }
}, et = function(a, t, n) {
  var e, o = $t[a];
  return typeof o == "string" ? e = o : t === 1 ? e = o.one : e = o.other.replace("{{count}}", t.toString()), n != null && n.addSuffix ? n.comparison && n.comparison > 0 ? "in " + e : e + " ago" : e;
};
function Z(r) {
  return function() {
    var a = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, t = a.width ? String(a.width) : r.defaultWidth, n = r.formats[t] || r.formats[r.defaultWidth];
    return n;
  };
}
var Qt = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
}, Bt = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
}, Xt = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
}, jt = {
  date: Z({
    formats: Qt,
    defaultWidth: "full"
  }),
  time: Z({
    formats: Bt,
    defaultWidth: "full"
  }),
  dateTime: Z({
    formats: Xt,
    defaultWidth: "full"
  })
}, Gt = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P"
}, tt = function(a, t, n, e) {
  return Gt[a];
};
function ie(r) {
  return function(a, t) {
    var n = t != null && t.context ? String(t.context) : "standalone", e;
    if (n === "formatting" && r.formattingValues) {
      var o = r.defaultFormattingWidth || r.defaultWidth, i = t != null && t.width ? String(t.width) : o;
      e = r.formattingValues[i] || r.formattingValues[o];
    } else {
      var u = r.defaultWidth, s = t != null && t.width ? String(t.width) : r.defaultWidth;
      e = r.values[s] || r.values[u];
    }
    var l = r.argumentCallback ? r.argumentCallback(a) : a;
    return e[l];
  };
}
var zt = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
}, Vt = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
}, Kt = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  wide: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
}, Jt = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
}, Zt = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
}, er = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
}, tr = function(a, t) {
  var n = Number(a), e = n % 100;
  if (e > 20 || e < 10)
    switch (e % 10) {
      case 1:
        return n + "st";
      case 2:
        return n + "nd";
      case 3:
        return n + "rd";
    }
  return n + "th";
}, rt = {
  ordinalNumber: tr,
  era: ie({
    values: zt,
    defaultWidth: "wide"
  }),
  quarter: ie({
    values: Vt,
    defaultWidth: "wide",
    argumentCallback: function(a) {
      return a - 1;
    }
  }),
  month: ie({
    values: Kt,
    defaultWidth: "wide"
  }),
  day: ie({
    values: Jt,
    defaultWidth: "wide"
  }),
  dayPeriod: ie({
    values: Zt,
    defaultWidth: "wide",
    formattingValues: er,
    defaultFormattingWidth: "wide"
  })
};
function oe(r) {
  return function(a) {
    var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, n = t.width, e = n && r.matchPatterns[n] || r.matchPatterns[r.defaultMatchWidth], o = a.match(e);
    if (!o)
      return null;
    var i = o[0], u = n && r.parsePatterns[n] || r.parsePatterns[r.defaultParseWidth], s = Array.isArray(u) ? nr(u, function(d) {
      return d.test(i);
    }) : rr(u, function(d) {
      return d.test(i);
    }), l;
    l = r.valueCallback ? r.valueCallback(s) : s, l = t.valueCallback ? t.valueCallback(l) : l;
    var v = a.slice(i.length);
    return {
      value: l,
      rest: v
    };
  };
}
function rr(r, a) {
  for (var t in r)
    if (r.hasOwnProperty(t) && a(r[t]))
      return t;
}
function nr(r, a) {
  for (var t = 0; t < r.length; t++)
    if (a(r[t]))
      return t;
}
function ar(r) {
  return function(a) {
    var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, n = a.match(r.matchPattern);
    if (!n) return null;
    var e = n[0], o = a.match(r.parsePattern);
    if (!o) return null;
    var i = r.valueCallback ? r.valueCallback(o[0]) : o[0];
    i = t.valueCallback ? t.valueCallback(i) : i;
    var u = a.slice(e.length);
    return {
      value: i,
      rest: u
    };
  };
}
var ir = /^(\d+)(th|st|nd|rd)?/i, or = /\d+/i, ur = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
}, sr = {
  any: [/^b/i, /^(a|c)/i]
}, cr = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
}, lr = {
  any: [/1/i, /2/i, /3/i, /4/i]
}, fr = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
}, dr = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^may/i, /^jun/i, /^jul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
}, vr = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
}, mr = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
}, hr = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
}, wr = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
}, nt = {
  ordinalNumber: ar({
    matchPattern: ir,
    parsePattern: or,
    valueCallback: function(a) {
      return parseInt(a, 10);
    }
  }),
  era: oe({
    matchPatterns: ur,
    defaultMatchWidth: "wide",
    parsePatterns: sr,
    defaultParseWidth: "any"
  }),
  quarter: oe({
    matchPatterns: cr,
    defaultMatchWidth: "wide",
    parsePatterns: lr,
    defaultParseWidth: "any",
    valueCallback: function(a) {
      return a + 1;
    }
  }),
  month: oe({
    matchPatterns: fr,
    defaultMatchWidth: "wide",
    parsePatterns: dr,
    defaultParseWidth: "any"
  }),
  day: oe({
    matchPatterns: vr,
    defaultMatchWidth: "wide",
    parsePatterns: mr,
    defaultParseWidth: "any"
  }),
  dayPeriod: oe({
    matchPatterns: hr,
    defaultMatchWidth: "any",
    parsePatterns: wr,
    defaultParseWidth: "any"
  })
}, Me = {
  code: "en-US",
  formatDistance: et,
  formatLong: jt,
  formatRelative: tt,
  localize: rt,
  match: nt,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
}, gr = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, pr = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, yr = /^'([^]*?)'?$/, br = /''/g, Tr = /[a-zA-Z]/;
function Pe(r, a, t) {
  var n, e, o, i, u, s, l, v, d, M, m, P, I, W;
  D(2, arguments);
  var R = String(a), E = K(), N = (n = (e = void 0) !== null && e !== void 0 ? e : E.locale) !== null && n !== void 0 ? n : Me, F = A((o = (i = (u = (s = void 0) !== null && s !== void 0 ? s : void 0) !== null && u !== void 0 ? u : E.firstWeekContainsDate) !== null && i !== void 0 ? i : (l = E.locale) === null || l === void 0 || (v = l.options) === null || v === void 0 ? void 0 : v.firstWeekContainsDate) !== null && o !== void 0 ? o : 1);
  if (!(F >= 1 && F <= 7))
    throw new RangeError("firstWeekContainsDate must be between 1 and 7 inclusively");
  var Q = A((d = (M = (m = (P = void 0) !== null && P !== void 0 ? P : void 0) !== null && m !== void 0 ? m : E.weekStartsOn) !== null && M !== void 0 ? M : (I = E.locale) === null || I === void 0 || (W = I.options) === null || W === void 0 ? void 0 : W.weekStartsOn) !== null && d !== void 0 ? d : 0);
  if (!(Q >= 0 && Q <= 6))
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  if (!N.localize)
    throw new RangeError("locale must contain localize property");
  if (!N.formatLong)
    throw new RangeError("locale must contain formatLong property");
  var B = y(r);
  if (!Mt(B))
    throw new RangeError("Invalid time value");
  var te = fe(B), re = je(B, te), ne = {
    firstWeekContainsDate: F,
    weekStartsOn: Q,
    locale: N,
    _originalDate: B
  }, he = R.match(pr).map(function(_) {
    var H = _[0];
    if (H === "p" || H === "P") {
      var j = ye[H];
      return j(_, N.formatLong);
    }
    return _;
  }).join("").match(gr).map(function(_) {
    if (_ === "''")
      return "'";
    var H = _[0];
    if (H === "'")
      return Dr(_);
    var j = Rt[H];
    if (j)
      return Ze(_) && de(_, a, String(r)), Je(_) && de(_, a, String(r)), j(re, _, N.localize, ne);
    if (H.match(Tr))
      throw new RangeError("Format string contains an unescaped latin alphabet character `" + H + "`");
    return _;
  }).join("");
  return he;
}
function Dr(r) {
  var a = r.match(yr);
  return a ? a[1].replace(br, "'") : r;
}
function Ce(r, a) {
  if (r == null)
    throw new TypeError("assign requires that input parameter not be null or undefined");
  for (var t in a)
    Object.prototype.hasOwnProperty.call(a, t) && (r[t] = a[t]);
  return r;
}
function Mr(r) {
  return Ce({}, r);
}
var Re = 1440, Pr = 2520, pe = 43200, Cr = 86400;
function xr(r, a, t) {
  var n, e;
  D(2, arguments);
  var o = K(), i = (n = (e = t == null ? void 0 : t.locale) !== null && e !== void 0 ? e : o.locale) !== null && n !== void 0 ? n : Me;
  if (!i.formatDistance)
    throw new RangeError("locale must contain formatDistance property");
  var u = le(r, a);
  if (isNaN(u))
    throw new RangeError("Invalid time value");
  var s = Ce(Mr(t), {
    addSuffix: !!(t != null && t.addSuffix),
    comparison: u
  }), l, v;
  u > 0 ? (l = y(a), v = y(r)) : (l = y(r), v = y(a));
  var d = _t(v, l), M = (fe(v) - fe(l)) / 1e3, m = Math.round((d - M) / 60), P;
  if (m < 2)
    return t != null && t.includeSeconds ? d < 5 ? i.formatDistance("lessThanXSeconds", 5, s) : d < 10 ? i.formatDistance("lessThanXSeconds", 10, s) : d < 20 ? i.formatDistance("lessThanXSeconds", 20, s) : d < 40 ? i.formatDistance("halfAMinute", 0, s) : d < 60 ? i.formatDistance("lessThanXMinutes", 1, s) : i.formatDistance("xMinutes", 1, s) : m === 0 ? i.formatDistance("lessThanXMinutes", 1, s) : i.formatDistance("xMinutes", m, s);
  if (m < 45)
    return i.formatDistance("xMinutes", m, s);
  if (m < 90)
    return i.formatDistance("aboutXHours", 1, s);
  if (m < Re) {
    var I = Math.round(m / 60);
    return i.formatDistance("aboutXHours", I, s);
  } else {
    if (m < Pr)
      return i.formatDistance("xDays", 1, s);
    if (m < pe) {
      var W = Math.round(m / Re);
      return i.formatDistance("xDays", W, s);
    } else if (m < Cr)
      return P = Math.round(m / pe), i.formatDistance("aboutXMonths", P, s);
  }
  if (P = Yt(v, l), P < 12) {
    var R = Math.round(m / pe);
    return i.formatDistance("xMonths", R, s);
  } else {
    var E = P % 12, N = Math.floor(P / 12);
    return E < 3 ? i.formatDistance("aboutXYears", N, s) : E < 9 ? i.formatDistance("overXYears", N, s) : i.formatDistance("almostXYears", N + 1, s);
  }
}
function qe(r, a) {
  (a == null || a > r.length) && (a = r.length);
  for (var t = 0, n = Array(a); t < a; t++) n[t] = r[t];
  return n;
}
function Or(r, a) {
  if (r) {
    if (typeof r == "string") return qe(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return t === "Object" && r.constructor && (t = r.constructor.name), t === "Map" || t === "Set" ? Array.from(r) : t === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? qe(r, a) : void 0;
  }
}
function Se(r, a) {
  var t = typeof Symbol < "u" && r[Symbol.iterator] || r["@@iterator"];
  if (!t) {
    if (Array.isArray(r) || (t = Or(r)) || a) {
      t && (r = t);
      var n = 0, e = function() {
      };
      return {
        s: e,
        n: function() {
          return n >= r.length ? {
            done: !0
          } : {
            done: !1,
            value: r[n++]
          };
        },
        e: function(l) {
          throw l;
        },
        f: e
      };
    }
    throw new TypeError(`Invalid attempt to iterate non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
  }
  var o, i = !0, u = !1;
  return {
    s: function() {
      t = t.call(r);
    },
    n: function() {
      var l = t.next();
      return i = l.done, l;
    },
    e: function(l) {
      u = !0, o = l;
    },
    f: function() {
      try {
        i || t.return == null || t.return();
      } finally {
        if (u) throw o;
      }
    }
  };
}
function f(r) {
  if (r === void 0) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  return r;
}
function be(r, a) {
  return be = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function(t, n) {
    return t.__proto__ = n, t;
  }, be(r, a);
}
function g(r, a) {
  if (typeof a != "function" && a !== null) throw new TypeError("Super expression must either be null or a function");
  r.prototype = Object.create(a && a.prototype, {
    constructor: {
      value: r,
      writable: !0,
      configurable: !0
    }
  }), Object.defineProperty(r, "prototype", {
    writable: !1
  }), a && be(r, a);
}
function ve(r) {
  return ve = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function(a) {
    return a.__proto__ || Object.getPrototypeOf(a);
  }, ve(r);
}
function at() {
  try {
    var r = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function() {
    }));
  } catch {
  }
  return (at = function() {
    return !!r;
  })();
}
function kr(r, a) {
  if (a && ($(a) == "object" || typeof a == "function")) return a;
  if (a !== void 0) throw new TypeError("Derived constructors may only return object or undefined");
  return f(r);
}
function p(r) {
  var a = at();
  return function() {
    var t, n = ve(r);
    if (a) {
      var e = ve(this).constructor;
      t = Reflect.construct(n, arguments, e);
    } else t = n.apply(this, arguments);
    return kr(this, t);
  };
}
function h(r, a) {
  if (!(r instanceof a)) throw new TypeError("Cannot call a class as a function");
}
function Ur(r, a) {
  if ($(r) != "object" || !r) return r;
  var t = r[Symbol.toPrimitive];
  if (t !== void 0) {
    var n = t.call(r, a || "default");
    if ($(n) != "object") return n;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (a === "string" ? String : Number)(r);
}
function it(r) {
  var a = Ur(r, "string");
  return $(a) == "symbol" ? a : a + "";
}
function Er(r, a) {
  for (var t = 0; t < a.length; t++) {
    var n = a[t];
    n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(r, it(n.key), n);
  }
}
function w(r, a, t) {
  return a && Er(r.prototype, a), Object.defineProperty(r, "prototype", {
    writable: !1
  }), r;
}
function c(r, a, t) {
  return (a = it(a)) in r ? Object.defineProperty(r, a, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : r[a] = t, r;
}
var Yr = 10, ot = /* @__PURE__ */ function() {
  function r() {
    h(this, r), c(this, "priority", void 0), c(this, "subPriority", 0);
  }
  return w(r, [{
    key: "validate",
    value: function(t, n) {
      return !0;
    }
  }]), r;
}(), _r = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t(n, e, o, i, u) {
    var s;
    return h(this, t), s = a.call(this), s.value = n, s.validateValue = e, s.setValue = o, s.priority = i, u && (s.subPriority = u), s;
  }
  return w(t, [{
    key: "validate",
    value: function(e, o) {
      return this.validateValue(e, this.value, o);
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return this.setValue(e, o, this.value, i);
    }
  }]), t;
}(ot), Wr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", Yr), c(f(n), "subPriority", -1), n;
  }
  return w(t, [{
    key: "set",
    value: function(e, o) {
      if (o.timestampIsSet)
        return e;
      var i = /* @__PURE__ */ new Date(0);
      return i.setFullYear(e.getUTCFullYear(), e.getUTCMonth(), e.getUTCDate()), i.setHours(e.getUTCHours(), e.getUTCMinutes(), e.getUTCSeconds(), e.getUTCMilliseconds()), i;
    }
  }]), t;
}(ot), b = /* @__PURE__ */ function() {
  function r() {
    h(this, r), c(this, "incompatibleTokens", void 0), c(this, "priority", void 0), c(this, "subPriority", void 0);
  }
  return w(r, [{
    key: "run",
    value: function(t, n, e, o) {
      var i = this.parse(t, n, e, o);
      return i ? {
        setter: new _r(i.value, this.validate, this.set, this.priority, this.subPriority),
        rest: i.rest
      } : null;
    }
  }, {
    key: "validate",
    value: function(t, n, e) {
      return !0;
    }
  }]), r;
}(), Ir = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 140), c(f(n), "incompatibleTokens", ["R", "u", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "G":
        case "GG":
        case "GGG":
          return i.era(e, {
            width: "abbreviated"
          }) || i.era(e, {
            width: "narrow"
          });
        case "GGGGG":
          return i.era(e, {
            width: "narrow"
          });
        case "GGGG":
        default:
          return i.era(e, {
            width: "wide"
          }) || i.era(e, {
            width: "abbreviated"
          }) || i.era(e, {
            width: "narrow"
          });
      }
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return o.era = i, e.setUTCFullYear(i, 0, 1), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), k = {
  month: /^(1[0-2]|0?\d)/,
  // 0 to 12
  date: /^(3[0-1]|[0-2]?\d)/,
  // 0 to 31
  dayOfYear: /^(36[0-6]|3[0-5]\d|[0-2]?\d?\d)/,
  // 0 to 366
  week: /^(5[0-3]|[0-4]?\d)/,
  // 0 to 53
  hour23h: /^(2[0-3]|[0-1]?\d)/,
  // 0 to 23
  hour24h: /^(2[0-4]|[0-1]?\d)/,
  // 0 to 24
  hour11h: /^(1[0-1]|0?\d)/,
  // 0 to 11
  hour12h: /^(1[0-2]|0?\d)/,
  // 0 to 12
  minute: /^[0-5]?\d/,
  // 0 to 59
  second: /^[0-5]?\d/,
  // 0 to 59
  singleDigit: /^\d/,
  // 0 to 9
  twoDigits: /^\d{1,2}/,
  // 0 to 99
  threeDigits: /^\d{1,3}/,
  // 0 to 999
  fourDigits: /^\d{1,4}/,
  // 0 to 9999
  anyDigitsSigned: /^-?\d+/,
  singleDigitSigned: /^-?\d/,
  // 0 to 9, -0 to -9
  twoDigitsSigned: /^-?\d{1,2}/,
  // 0 to 99, -0 to -99
  threeDigitsSigned: /^-?\d{1,3}/,
  // 0 to 999, -0 to -999
  fourDigitsSigned: /^-?\d{1,4}/
  // 0 to 9999, -0 to -9999
}, q = {
  basicOptionalMinutes: /^([+-])(\d{2})(\d{2})?|Z/,
  basic: /^([+-])(\d{2})(\d{2})|Z/,
  basicOptionalSeconds: /^([+-])(\d{2})(\d{2})((\d{2}))?|Z/,
  extended: /^([+-])(\d{2}):(\d{2})|Z/,
  extendedOptionalSeconds: /^([+-])(\d{2}):(\d{2})(:(\d{2}))?|Z/
};
function U(r, a) {
  return r && {
    value: a(r.value),
    rest: r.rest
  };
}
function x(r, a) {
  var t = a.match(r);
  return t ? {
    value: parseInt(t[0], 10),
    rest: a.slice(t[0].length)
  } : null;
}
function S(r, a) {
  var t = a.match(r);
  if (!t)
    return null;
  if (t[0] === "Z")
    return {
      value: 0,
      rest: a.slice(1)
    };
  var n = t[1] === "+" ? 1 : -1, e = t[2] ? parseInt(t[2], 10) : 0, o = t[3] ? parseInt(t[3], 10) : 0, i = t[5] ? parseInt(t[5], 10) : 0;
  return {
    value: n * (e * bt + o * yt + i * Tt),
    rest: a.slice(t[0].length)
  };
}
function ut(r) {
  return x(k.anyDigitsSigned, r);
}
function O(r, a) {
  switch (r) {
    case 1:
      return x(k.singleDigit, a);
    case 2:
      return x(k.twoDigits, a);
    case 3:
      return x(k.threeDigits, a);
    case 4:
      return x(k.fourDigits, a);
    default:
      return x(new RegExp("^\\d{1," + r + "}"), a);
  }
}
function me(r, a) {
  switch (r) {
    case 1:
      return x(k.singleDigitSigned, a);
    case 2:
      return x(k.twoDigitsSigned, a);
    case 3:
      return x(k.threeDigitsSigned, a);
    case 4:
      return x(k.fourDigitsSigned, a);
    default:
      return x(new RegExp("^-?\\d{1," + r + "}"), a);
  }
}
function xe(r) {
  switch (r) {
    case "morning":
      return 4;
    case "evening":
      return 17;
    case "pm":
    case "noon":
    case "afternoon":
      return 12;
    case "am":
    case "midnight":
    case "night":
    default:
      return 0;
  }
}
function st(r, a) {
  var t = a > 0, n = t ? a : 1 - a, e;
  if (n <= 50)
    e = r || 100;
  else {
    var o = n + 50, i = Math.floor(o / 100) * 100, u = r >= o % 100;
    e = r + i - (u ? 100 : 0);
  }
  return t ? e : 1 - e;
}
function ct(r) {
  return r % 400 === 0 || r % 4 === 0 && r % 100 !== 0;
}
var Nr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 130), c(f(n), "incompatibleTokens", ["Y", "R", "u", "w", "I", "i", "e", "c", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      var u = function(l) {
        return {
          year: l,
          isTwoDigitYear: o === "yy"
        };
      };
      switch (o) {
        case "y":
          return U(O(4, e), u);
        case "yo":
          return U(i.ordinalNumber(e, {
            unit: "year"
          }), u);
        default:
          return U(O(o.length, e), u);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o.isTwoDigitYear || o.year > 0;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      var u = e.getUTCFullYear();
      if (i.isTwoDigitYear) {
        var s = st(i.year, u);
        return e.setUTCFullYear(s, 0, 1), e.setUTCHours(0, 0, 0, 0), e;
      }
      var l = !("era" in o) || o.era === 1 ? i.year : 1 - i.year;
      return e.setUTCFullYear(l, 0, 1), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), Ar = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 130), c(f(n), "incompatibleTokens", ["y", "R", "u", "Q", "q", "M", "L", "I", "d", "D", "i", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      var u = function(l) {
        return {
          year: l,
          isTwoDigitYear: o === "YY"
        };
      };
      switch (o) {
        case "Y":
          return U(O(4, e), u);
        case "Yo":
          return U(i.ordinalNumber(e, {
            unit: "year"
          }), u);
        default:
          return U(O(o.length, e), u);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o.isTwoDigitYear || o.year > 0;
    }
  }, {
    key: "set",
    value: function(e, o, i, u) {
      var s = De(e, u);
      if (i.isTwoDigitYear) {
        var l = st(i.year, s);
        return e.setUTCFullYear(l, 0, u.firstWeekContainsDate), e.setUTCHours(0, 0, 0, 0), V(e, u);
      }
      var v = !("era" in o) || o.era === 1 ? i.year : 1 - i.year;
      return e.setUTCFullYear(v, 0, u.firstWeekContainsDate), e.setUTCHours(0, 0, 0, 0), V(e, u);
    }
  }]), t;
}(b), Hr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 130), c(f(n), "incompatibleTokens", ["G", "y", "Y", "u", "Q", "q", "M", "L", "w", "d", "D", "e", "c", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o) {
      return me(o === "R" ? 4 : o.length, e);
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      var u = /* @__PURE__ */ new Date(0);
      return u.setUTCFullYear(i, 0, 4), u.setUTCHours(0, 0, 0, 0), ee(u);
    }
  }]), t;
}(b), Lr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 130), c(f(n), "incompatibleTokens", ["G", "y", "Y", "R", "w", "I", "i", "e", "c", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o) {
      return me(o === "u" ? 4 : o.length, e);
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCFullYear(i, 0, 1), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), Rr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 120), c(f(n), "incompatibleTokens", ["Y", "R", "q", "M", "L", "w", "I", "d", "D", "i", "e", "c", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "Q":
        case "QQ":
          return O(o.length, e);
        case "Qo":
          return i.ordinalNumber(e, {
            unit: "quarter"
          });
        case "QQQ":
          return i.quarter(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.quarter(e, {
            width: "narrow",
            context: "formatting"
          });
        case "QQQQQ":
          return i.quarter(e, {
            width: "narrow",
            context: "formatting"
          });
        case "QQQQ":
        default:
          return i.quarter(e, {
            width: "wide",
            context: "formatting"
          }) || i.quarter(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.quarter(e, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 1 && o <= 4;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCMonth((i - 1) * 3, 1), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), qr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 120), c(f(n), "incompatibleTokens", ["Y", "R", "Q", "M", "L", "w", "I", "d", "D", "i", "e", "c", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "q":
        case "qq":
          return O(o.length, e);
        case "qo":
          return i.ordinalNumber(e, {
            unit: "quarter"
          });
        case "qqq":
          return i.quarter(e, {
            width: "abbreviated",
            context: "standalone"
          }) || i.quarter(e, {
            width: "narrow",
            context: "standalone"
          });
        case "qqqqq":
          return i.quarter(e, {
            width: "narrow",
            context: "standalone"
          });
        case "qqqq":
        default:
          return i.quarter(e, {
            width: "wide",
            context: "standalone"
          }) || i.quarter(e, {
            width: "abbreviated",
            context: "standalone"
          }) || i.quarter(e, {
            width: "narrow",
            context: "standalone"
          });
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 1 && o <= 4;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCMonth((i - 1) * 3, 1), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), Sr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "incompatibleTokens", ["Y", "R", "q", "Q", "L", "w", "I", "D", "i", "e", "c", "t", "T"]), c(f(n), "priority", 110), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      var u = function(l) {
        return l - 1;
      };
      switch (o) {
        case "M":
          return U(x(k.month, e), u);
        case "MM":
          return U(O(2, e), u);
        case "Mo":
          return U(i.ordinalNumber(e, {
            unit: "month"
          }), u);
        case "MMM":
          return i.month(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.month(e, {
            width: "narrow",
            context: "formatting"
          });
        case "MMMMM":
          return i.month(e, {
            width: "narrow",
            context: "formatting"
          });
        case "MMMM":
        default:
          return i.month(e, {
            width: "wide",
            context: "formatting"
          }) || i.month(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.month(e, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 11;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCMonth(i, 1), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), Fr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 110), c(f(n), "incompatibleTokens", ["Y", "R", "q", "Q", "M", "w", "I", "D", "i", "e", "c", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      var u = function(l) {
        return l - 1;
      };
      switch (o) {
        case "L":
          return U(x(k.month, e), u);
        case "LL":
          return U(O(2, e), u);
        case "Lo":
          return U(i.ordinalNumber(e, {
            unit: "month"
          }), u);
        case "LLL":
          return i.month(e, {
            width: "abbreviated",
            context: "standalone"
          }) || i.month(e, {
            width: "narrow",
            context: "standalone"
          });
        case "LLLLL":
          return i.month(e, {
            width: "narrow",
            context: "standalone"
          });
        case "LLLL":
        default:
          return i.month(e, {
            width: "wide",
            context: "standalone"
          }) || i.month(e, {
            width: "abbreviated",
            context: "standalone"
          }) || i.month(e, {
            width: "narrow",
            context: "standalone"
          });
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 11;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCMonth(i, 1), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b);
function $r(r, a, t) {
  D(2, arguments);
  var n = y(r), e = A(a), o = Ve(n, t) - e;
  return n.setUTCDate(n.getUTCDate() - o * 7), n;
}
var Qr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 100), c(f(n), "incompatibleTokens", ["y", "R", "u", "q", "Q", "M", "L", "I", "d", "D", "i", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "w":
          return x(k.week, e);
        case "wo":
          return i.ordinalNumber(e, {
            unit: "week"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 1 && o <= 53;
    }
  }, {
    key: "set",
    value: function(e, o, i, u) {
      return V($r(e, i, u), u);
    }
  }]), t;
}(b);
function Br(r, a) {
  D(2, arguments);
  var t = y(r), n = A(a), e = ze(t) - n;
  return t.setUTCDate(t.getUTCDate() - e * 7), t;
}
var Xr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 100), c(f(n), "incompatibleTokens", ["y", "Y", "u", "q", "Q", "M", "L", "w", "d", "D", "e", "c", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "I":
          return x(k.week, e);
        case "Io":
          return i.ordinalNumber(e, {
            unit: "week"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 1 && o <= 53;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return ee(Br(e, i));
    }
  }]), t;
}(b), jr = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], Gr = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], zr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 90), c(f(n), "subPriority", 1), c(f(n), "incompatibleTokens", ["Y", "R", "q", "Q", "w", "I", "D", "i", "e", "c", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "d":
          return x(k.date, e);
        case "do":
          return i.ordinalNumber(e, {
            unit: "date"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      var i = e.getUTCFullYear(), u = ct(i), s = e.getUTCMonth();
      return u ? o >= 1 && o <= Gr[s] : o >= 1 && o <= jr[s];
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCDate(i), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), Vr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 90), c(f(n), "subpriority", 1), c(f(n), "incompatibleTokens", ["Y", "R", "q", "Q", "M", "L", "w", "I", "d", "E", "i", "e", "c", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "D":
        case "DD":
          return x(k.dayOfYear, e);
        case "Do":
          return i.ordinalNumber(e, {
            unit: "date"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      var i = e.getUTCFullYear(), u = ct(i);
      return u ? o >= 1 && o <= 366 : o >= 1 && o <= 365;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCMonth(0, i), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b);
function Oe(r, a, t) {
  var n, e, o, i, u, s, l, v;
  D(2, arguments);
  var d = K(), M = A((n = (e = (o = (i = t == null ? void 0 : t.weekStartsOn) !== null && i !== void 0 ? i : t == null || (u = t.locale) === null || u === void 0 || (s = u.options) === null || s === void 0 ? void 0 : s.weekStartsOn) !== null && o !== void 0 ? o : d.weekStartsOn) !== null && e !== void 0 ? e : (l = d.locale) === null || l === void 0 || (v = l.options) === null || v === void 0 ? void 0 : v.weekStartsOn) !== null && n !== void 0 ? n : 0);
  if (!(M >= 0 && M <= 6))
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  var m = y(r), P = A(a), I = m.getUTCDay(), W = P % 7, R = (W + 7) % 7, E = (R < M ? 7 : 0) + P - I;
  return m.setUTCDate(m.getUTCDate() + E), m;
}
var Kr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 90), c(f(n), "incompatibleTokens", ["D", "i", "e", "c", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "E":
        case "EE":
        case "EEE":
          return i.day(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          });
        case "EEEEE":
          return i.day(e, {
            width: "narrow",
            context: "formatting"
          });
        case "EEEEEE":
          return i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          });
        case "EEEE":
        default:
          return i.day(e, {
            width: "wide",
            context: "formatting"
          }) || i.day(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 6;
    }
  }, {
    key: "set",
    value: function(e, o, i, u) {
      return e = Oe(e, i, u), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), Jr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 90), c(f(n), "incompatibleTokens", ["y", "R", "u", "q", "Q", "M", "L", "I", "d", "D", "E", "i", "c", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i, u) {
      var s = function(v) {
        var d = Math.floor((v - 1) / 7) * 7;
        return (v + u.weekStartsOn + 6) % 7 + d;
      };
      switch (o) {
        case "e":
        case "ee":
          return U(O(o.length, e), s);
        case "eo":
          return U(i.ordinalNumber(e, {
            unit: "day"
          }), s);
        case "eee":
          return i.day(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          });
        case "eeeee":
          return i.day(e, {
            width: "narrow",
            context: "formatting"
          });
        case "eeeeee":
          return i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          });
        case "eeee":
        default:
          return i.day(e, {
            width: "wide",
            context: "formatting"
          }) || i.day(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 6;
    }
  }, {
    key: "set",
    value: function(e, o, i, u) {
      return e = Oe(e, i, u), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), Zr = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 90), c(f(n), "incompatibleTokens", ["y", "R", "u", "q", "Q", "M", "L", "I", "d", "D", "E", "i", "e", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i, u) {
      var s = function(v) {
        var d = Math.floor((v - 1) / 7) * 7;
        return (v + u.weekStartsOn + 6) % 7 + d;
      };
      switch (o) {
        case "c":
        case "cc":
          return U(O(o.length, e), s);
        case "co":
          return U(i.ordinalNumber(e, {
            unit: "day"
          }), s);
        case "ccc":
          return i.day(e, {
            width: "abbreviated",
            context: "standalone"
          }) || i.day(e, {
            width: "short",
            context: "standalone"
          }) || i.day(e, {
            width: "narrow",
            context: "standalone"
          });
        case "ccccc":
          return i.day(e, {
            width: "narrow",
            context: "standalone"
          });
        case "cccccc":
          return i.day(e, {
            width: "short",
            context: "standalone"
          }) || i.day(e, {
            width: "narrow",
            context: "standalone"
          });
        case "cccc":
        default:
          return i.day(e, {
            width: "wide",
            context: "standalone"
          }) || i.day(e, {
            width: "abbreviated",
            context: "standalone"
          }) || i.day(e, {
            width: "short",
            context: "standalone"
          }) || i.day(e, {
            width: "narrow",
            context: "standalone"
          });
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 6;
    }
  }, {
    key: "set",
    value: function(e, o, i, u) {
      return e = Oe(e, i, u), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b);
function en(r, a) {
  D(2, arguments);
  var t = A(a);
  t % 7 === 0 && (t = t - 7);
  var n = 1, e = y(r), o = e.getUTCDay(), i = t % 7, u = (i + 7) % 7, s = (u < n ? 7 : 0) + t - o;
  return e.setUTCDate(e.getUTCDate() + s), e;
}
var tn = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 90), c(f(n), "incompatibleTokens", ["y", "Y", "u", "q", "Q", "M", "L", "w", "d", "D", "E", "e", "c", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      var u = function(l) {
        return l === 0 ? 7 : l;
      };
      switch (o) {
        case "i":
        case "ii":
          return O(o.length, e);
        case "io":
          return i.ordinalNumber(e, {
            unit: "day"
          });
        case "iii":
          return U(i.day(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          }), u);
        case "iiiii":
          return U(i.day(e, {
            width: "narrow",
            context: "formatting"
          }), u);
        case "iiiiii":
          return U(i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          }), u);
        case "iiii":
        default:
          return U(i.day(e, {
            width: "wide",
            context: "formatting"
          }) || i.day(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.day(e, {
            width: "short",
            context: "formatting"
          }) || i.day(e, {
            width: "narrow",
            context: "formatting"
          }), u);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 1 && o <= 7;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e = en(e, i), e.setUTCHours(0, 0, 0, 0), e;
    }
  }]), t;
}(b), rn = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 80), c(f(n), "incompatibleTokens", ["b", "B", "H", "k", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "a":
        case "aa":
        case "aaa":
          return i.dayPeriod(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
        case "aaaaa":
          return i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
        case "aaaa":
        default:
          return i.dayPeriod(e, {
            width: "wide",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCHours(xe(i), 0, 0, 0), e;
    }
  }]), t;
}(b), nn = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 80), c(f(n), "incompatibleTokens", ["a", "B", "H", "k", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "b":
        case "bb":
        case "bbb":
          return i.dayPeriod(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
        case "bbbbb":
          return i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
        case "bbbb":
        default:
          return i.dayPeriod(e, {
            width: "wide",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCHours(xe(i), 0, 0, 0), e;
    }
  }]), t;
}(b), an = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 80), c(f(n), "incompatibleTokens", ["a", "b", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "B":
        case "BB":
        case "BBB":
          return i.dayPeriod(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
        case "BBBBB":
          return i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
        case "BBBB":
        default:
          return i.dayPeriod(e, {
            width: "wide",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "abbreviated",
            context: "formatting"
          }) || i.dayPeriod(e, {
            width: "narrow",
            context: "formatting"
          });
      }
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCHours(xe(i), 0, 0, 0), e;
    }
  }]), t;
}(b), on = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 70), c(f(n), "incompatibleTokens", ["H", "K", "k", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "h":
          return x(k.hour12h, e);
        case "ho":
          return i.ordinalNumber(e, {
            unit: "hour"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 1 && o <= 12;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      var u = e.getUTCHours() >= 12;
      return u && i < 12 ? e.setUTCHours(i + 12, 0, 0, 0) : !u && i === 12 ? e.setUTCHours(0, 0, 0, 0) : e.setUTCHours(i, 0, 0, 0), e;
    }
  }]), t;
}(b), un = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 70), c(f(n), "incompatibleTokens", ["a", "b", "h", "K", "k", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "H":
          return x(k.hour23h, e);
        case "Ho":
          return i.ordinalNumber(e, {
            unit: "hour"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 23;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCHours(i, 0, 0, 0), e;
    }
  }]), t;
}(b), sn = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 70), c(f(n), "incompatibleTokens", ["h", "H", "k", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "K":
          return x(k.hour11h, e);
        case "Ko":
          return i.ordinalNumber(e, {
            unit: "hour"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 11;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      var u = e.getUTCHours() >= 12;
      return u && i < 12 ? e.setUTCHours(i + 12, 0, 0, 0) : e.setUTCHours(i, 0, 0, 0), e;
    }
  }]), t;
}(b), cn = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 70), c(f(n), "incompatibleTokens", ["a", "b", "h", "H", "K", "t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "k":
          return x(k.hour24h, e);
        case "ko":
          return i.ordinalNumber(e, {
            unit: "hour"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 1 && o <= 24;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      var u = i <= 24 ? i % 24 : i;
      return e.setUTCHours(u, 0, 0, 0), e;
    }
  }]), t;
}(b), ln = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 60), c(f(n), "incompatibleTokens", ["t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "m":
          return x(k.minute, e);
        case "mo":
          return i.ordinalNumber(e, {
            unit: "minute"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 59;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCMinutes(i, 0, 0), e;
    }
  }]), t;
}(b), fn = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 50), c(f(n), "incompatibleTokens", ["t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o, i) {
      switch (o) {
        case "s":
          return x(k.second, e);
        case "so":
          return i.ordinalNumber(e, {
            unit: "second"
          });
        default:
          return O(o.length, e);
      }
    }
  }, {
    key: "validate",
    value: function(e, o) {
      return o >= 0 && o <= 59;
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCSeconds(i, 0), e;
    }
  }]), t;
}(b), dn = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 30), c(f(n), "incompatibleTokens", ["t", "T"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o) {
      var i = function(s) {
        return Math.floor(s * Math.pow(10, -o.length + 3));
      };
      return U(O(o.length, e), i);
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return e.setUTCMilliseconds(i), e;
    }
  }]), t;
}(b), vn = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 10), c(f(n), "incompatibleTokens", ["t", "T", "x"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o) {
      switch (o) {
        case "X":
          return S(q.basicOptionalMinutes, e);
        case "XX":
          return S(q.basic, e);
        case "XXXX":
          return S(q.basicOptionalSeconds, e);
        case "XXXXX":
          return S(q.extendedOptionalSeconds, e);
        case "XXX":
        default:
          return S(q.extended, e);
      }
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return o.timestampIsSet ? e : new Date(e.getTime() - i);
    }
  }]), t;
}(b), mn = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 10), c(f(n), "incompatibleTokens", ["t", "T", "X"]), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e, o) {
      switch (o) {
        case "x":
          return S(q.basicOptionalMinutes, e);
        case "xx":
          return S(q.basic, e);
        case "xxxx":
          return S(q.basicOptionalSeconds, e);
        case "xxxxx":
          return S(q.extendedOptionalSeconds, e);
        case "xxx":
        default:
          return S(q.extended, e);
      }
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return o.timestampIsSet ? e : new Date(e.getTime() - i);
    }
  }]), t;
}(b), hn = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 40), c(f(n), "incompatibleTokens", "*"), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e) {
      return ut(e);
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return [new Date(i * 1e3), {
        timestampIsSet: !0
      }];
    }
  }]), t;
}(b), wn = /* @__PURE__ */ function(r) {
  g(t, r);
  var a = p(t);
  function t() {
    var n;
    h(this, t);
    for (var e = arguments.length, o = new Array(e), i = 0; i < e; i++)
      o[i] = arguments[i];
    return n = a.call.apply(a, [this].concat(o)), c(f(n), "priority", 20), c(f(n), "incompatibleTokens", "*"), n;
  }
  return w(t, [{
    key: "parse",
    value: function(e) {
      return ut(e);
    }
  }, {
    key: "set",
    value: function(e, o, i) {
      return [new Date(i), {
        timestampIsSet: !0
      }];
    }
  }]), t;
}(b), gn = {
  G: new Ir(),
  y: new Nr(),
  Y: new Ar(),
  R: new Hr(),
  u: new Lr(),
  Q: new Rr(),
  q: new qr(),
  M: new Sr(),
  L: new Fr(),
  w: new Qr(),
  I: new Xr(),
  d: new zr(),
  D: new Vr(),
  E: new Kr(),
  e: new Jr(),
  c: new Zr(),
  i: new tn(),
  a: new rn(),
  b: new nn(),
  B: new an(),
  h: new on(),
  H: new un(),
  K: new sn(),
  k: new cn(),
  m: new ln(),
  s: new fn(),
  S: new dn(),
  X: new vn(),
  x: new mn(),
  t: new hn(),
  T: new wn()
}, pn = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, yn = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, bn = /^'([^]*?)'?$/, Tn = /''/g, Dn = /\S/, Mn = /[a-zA-Z]/;
function Pn(r, a, t, n) {
  var e, o, i, u, s, l, v, d, M, m, P, I, W, R;
  D(3, arguments);
  var E = String(r), N = String(a), F = K(), Q = (e = (o = void 0) !== null && o !== void 0 ? o : F.locale) !== null && e !== void 0 ? e : Me;
  if (!Q.match)
    throw new RangeError("locale must contain match property");
  var B = A((i = (u = (s = (l = void 0) !== null && l !== void 0 ? l : void 0) !== null && s !== void 0 ? s : F.firstWeekContainsDate) !== null && u !== void 0 ? u : (v = F.locale) === null || v === void 0 || (d = v.options) === null || d === void 0 ? void 0 : d.firstWeekContainsDate) !== null && i !== void 0 ? i : 1);
  if (!(B >= 1 && B <= 7))
    throw new RangeError("firstWeekContainsDate must be between 1 and 7 inclusively");
  var te = A((M = (m = (P = (I = void 0) !== null && I !== void 0 ? I : void 0) !== null && P !== void 0 ? P : F.weekStartsOn) !== null && m !== void 0 ? m : (W = F.locale) === null || W === void 0 || (R = W.options) === null || R === void 0 ? void 0 : R.weekStartsOn) !== null && M !== void 0 ? M : 0);
  if (!(te >= 0 && te <= 6))
    throw new RangeError("weekStartsOn must be between 0 and 6 inclusively");
  if (N === "")
    return E === "" ? y(t) : /* @__PURE__ */ new Date(NaN);
  var re = {
    firstWeekContainsDate: B,
    weekStartsOn: te,
    locale: Q
  }, ne = [new Wr()], he = N.match(yn).map(function(Y) {
    var C = Y[0];
    if (C in ye) {
      var L = ye[C];
      return L(Y, Q.formatLong);
    }
    return Y;
  }).join("").match(pn), _ = [], H = Se(he), j;
  try {
    var lt = function() {
      var C = j.value;
      !(n != null && n.useAdditionalWeekYearTokens) && Ze(C) && de(C, N, r), !(n != null && n.useAdditionalDayOfYearTokens) && Je(C) && de(C, N, r);
      var L = C[0], ce = gn[L];
      if (ce) {
        var _e = ce.incompatibleTokens;
        if (Array.isArray(_e)) {
          var We = _.find(function(Ie) {
            return _e.includes(Ie.token) || Ie.token === L;
          });
          if (We)
            throw new RangeError("The format string mustn't contain `".concat(We.fullToken, "` and `").concat(C, "` at the same time"));
        } else if (ce.incompatibleTokens === "*" && _.length > 0)
          throw new RangeError("The format string mustn't contain `".concat(C, "` and any other token at the same time"));
        _.push({
          token: L,
          fullToken: C
        });
        var ge = ce.run(E, C, Q.match, re);
        if (!ge)
          return {
            v: /* @__PURE__ */ new Date(NaN)
          };
        ne.push(ge.setter), E = ge.rest;
      } else {
        if (L.match(Mn))
          throw new RangeError("Format string contains an unescaped latin alphabet character `" + L + "`");
        if (C === "''" ? C = "'" : L === "'" && (C = Cn(C)), E.indexOf(C) === 0)
          E = E.slice(C.length);
        else
          return {
            v: /* @__PURE__ */ new Date(NaN)
          };
      }
    };
    for (H.s(); !(j = H.n()).done; ) {
      var ke = lt();
      if ($(ke) === "object") return ke.v;
    }
  } catch (Y) {
    H.e(Y);
  } finally {
    H.f();
  }
  if (E.length > 0 && Dn.test(E))
    return /* @__PURE__ */ new Date(NaN);
  var ft = ne.map(function(Y) {
    return Y.priority;
  }).sort(function(Y, C) {
    return C - Y;
  }).filter(function(Y, C, L) {
    return L.indexOf(Y) === C;
  }).map(function(Y) {
    return ne.filter(function(C) {
      return C.priority === Y;
    }).sort(function(C, L) {
      return L.subPriority - C.subPriority;
    });
  }).map(function(Y) {
    return Y[0];
  }), we = y(t);
  if (isNaN(we.getTime()))
    return /* @__PURE__ */ new Date(NaN);
  var ae = je(we, fe(we)), Ue = {}, ue = Se(ft), Ee;
  try {
    for (ue.s(); !(Ee = ue.n()).done; ) {
      var Ye = Ee.value;
      if (!Ye.validate(ae, re))
        return /* @__PURE__ */ new Date(NaN);
      var se = Ye.set(ae, Ue, re);
      Array.isArray(se) ? (ae = se[0], Ce(Ue, se[1])) : ae = se;
    }
  } catch (Y) {
    ue.e(Y);
  } finally {
    ue.f();
  }
  return ae;
}
function Cn(r) {
  return r.match(bn)[1].replace(Tn, "'");
}
function zn(r, a = /([8-9]|1\d|2[0-9]|3[01]) days|month|months|year|years/, t = "MM/dd/yyyy") {
  const n = xr(r.getTime(), /* @__PURE__ */ new Date(), {
    addSuffix: !0
  }).replace(/about/, "").replace(/less than a minute ago/, "Just now").replace(/1 minute ago/, "Just now").replace(/1 day ago/, "Yesterday").trim();
  return n.match(a) ? Pe(r, t) : n;
}
function Vn(r = "MM/dd/yyyy") {
  return Pe(/* @__PURE__ */ new Date(), r);
}
var xn = {
  full: "EEEE, d MMMM yyyy",
  long: "d MMMM yyyy",
  medium: "d MMM yyyy",
  short: "dd/MM/yyyy"
}, On = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
}, kn = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
}, Un = {
  date: Z({
    formats: xn,
    defaultWidth: "full"
  }),
  time: Z({
    formats: On,
    defaultWidth: "full"
  }),
  dateTime: Z({
    formats: kn,
    defaultWidth: "full"
  })
}, En = {
  code: "en-GB",
  formatDistance: et,
  formatLong: Un,
  formatRelative: tt,
  localize: rt,
  match: nt,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
const Yn = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: "MM/dd/yyyy"
}, Kn = {
  ...En,
  formatRelative: (r) => Yn[r]
}, Jn = (r) => {
  console.log("Error handled by mutation", { error: r });
}, Zn = (r, a, t) => r === 1 ? a : t, ea = (r) => r === !0 ? "Yes" : r === !1 ? "No" : "", _n = (r) => ({ label: r, value: r }), ta = (r) => r == null ? void 0 : r.split(",").filter((a) => !!a).map(_n), ra = (r) => !r || !Array.isArray(r) ? [] : r == null ? void 0 : r.map((a) => a == null ? void 0 : a.value), na = ({
  dateString: r,
  inputFormat: a = "yyyy-MM-dd",
  outputFormat: t = "MM/dd/yyyy",
  defaultPlaceholder: n = ""
}) => {
  if (!r)
    return n;
  const e = Pn(r, a, /* @__PURE__ */ new Date());
  return Pe(e, t);
};
function aa(r, ...a) {
  return (t) => a.reduce(
    (n, e) => n.replace(/:[^/]+/, `${t[e]}`),
    r
  );
}
const ia = (r) => {
  var t;
  return ((t = (Array.from(r)[0] === "/" ? r : `/${r}`).split("/")) == null ? void 0 : t[1]) || "";
};
function oa(r) {
  const a = Be();
  return z(() => {
    a.current = r;
  }), a.current;
}
const Fe = (r) => {
  r.preventDefault();
  const a = "Leave Site? Changes you made may not be saved.";
  return r.returnValue = a, a;
}, ua = (r) => {
  z(() => (r && window.addEventListener("beforeunload", Fe), () => {
    window.removeEventListener("beforeunload", Fe);
  }), [r]);
}, sa = (r) => {
  const [a, t] = Xe();
  return z(() => {
    r != null && r.current && t(r == null ? void 0 : r.current.getBoundingClientRect());
  }, [t, r]), a;
}, ca = (r, a, t = !0) => {
  z(() => {
    if (!a || !t)
      return;
    const n = setTimeout(r, a);
    return () => {
      clearTimeout(n);
    };
  }, [r, a, t]);
}, $e = (r, a, t) => ({
  value: r,
  timestamp: (/* @__PURE__ */ new Date()).getTime(),
  duration: a,
  elapsedDuration: t
}), Qe = ({
  value: r,
  duration: a,
  elapsedDuration: t,
  timestamp: n
}) => {
  const e = typeof a == "function" ? a(r) : a;
  if (t) {
    const o = (/* @__PURE__ */ new Date()).getTime() - n;
    return Math.max(e - o, 0);
  }
  return e;
}, la = (r, {
  duration: a = 500,
  skipLapsed: t = !0,
  elapsedDuration: n = !0
} = {}) => {
  const e = Be(), [o, i] = Xe([
    $e(r, a, n)
  ]), u = o[0], s = u == null ? void 0 : u.value, l = dt(() => {
    i((v) => {
      if (v.length === 1)
        return v;
      const d = v.slice(1);
      if (!d[1])
        return d;
      if (t)
        return d.slice(-1);
      const m = d[0], P = Qe({
        ...m,
        elapsedDuration: m.elapsedDuration && d.length === 1
      });
      return P > 0 ? (e.current = setTimeout(l, P), d) : (d.shift(), d);
    });
  }, [t]);
  return z(() => {
    i((v) => {
      const d = [...v], M = $e(
        r,
        a,
        n
      );
      if (d[d.length - 1].value === r)
        return v;
      if (d.push(M), v.length > 1)
        return d;
      const m = Qe(u);
      return m > 0 ? (e.current = setTimeout(l, m), d) : [M];
    });
  }, [r]), z(() => () => {
    clearTimeout(e.current);
  }, []), s;
};
function fa(r) {
  z(r, []);
}
export {
  mt as DEFAULT_ERROR_MESSAGE,
  ht as capitalize,
  Fn as capitalizeAndSingularize,
  $e as createStateQueueItem,
  Rn as createStorageUtil,
  Sn as defaultErrorParser,
  vt as defaultPrecisionResolver,
  Jn as errorHandledByMutation,
  $n as escapeHtml,
  na as formatStringDate,
  Qe as getDeferredValueTimeout,
  ia as getFirstUrlElementFromLink,
  ea as getIsTextingAllowedLabel,
  Nn as getScrollValue,
  ra as getValuesFromRichOptions,
  Kn as locale,
  Vn as makeFormattedToday,
  _n as makeRichOption,
  ta as makeRichOptionsFromCSV,
  aa as makeRouteParamsFn,
  Qn as newLinesHtml,
  wt as parseAmount,
  Xn as parseBytesToKBString,
  Gn as parseCentsToDollars,
  jn as parseFileName,
  Zn as pluralLabel,
  Ln as preventBubbling,
  qn as readableBytes,
  Hn as scrollToElement,
  An as setScrollValue,
  zn as timeAgo,
  Bn as titleCase,
  ua as useBeforeUnload,
  sa as useClientBoundingRect,
  fa as useEffectOnce,
  oa as usePrevious,
  ca as useTimeout,
  la as useTimeoutValue,
  In as validatePassword
};
