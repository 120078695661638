import { useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  CancelControllers,
  FilePercentsMap,
  UploadDocumentConfig,
  UploadFormType,
  UploadType,
} from '@liscio/api';

import { UploadFormProps } from './UploadForm';
import { UploadDocumentFormData } from '../../useUploadDocumentForm';
import {
  useUpdateDocument,
  useAttachDocument,
  useSendNewDocument,
} from 'fetch-utils/documents/documents-hooks';
import { makeFileId } from 'fetch-utils/documents/documentsCallsUtils';
import { FILES_DOMAIN, FILES_PATHS } from 'modules/files/route-constants';

type TypesWithoutEdit = Exclude<UploadFormType, UploadFormType.Edit>;

const FORM_TYPE_TO_UPLOAD_TYPE: { [Key in TypesWithoutEdit]: UploadType } = {
  [UploadFormType.NewFile]: UploadType.NewFile,
  [UploadFormType.MessageThread]: UploadType.Message,
  [UploadFormType.NewMessage]: UploadType.Message,
  [UploadFormType.Task]: UploadType.Task,
  [UploadFormType.Invoice]: UploadType.Invoice,
  [UploadFormType.Workflow]: UploadType.Workflow,
};

export type UseUploadFormHelperProps = UploadFormProps & {
  fileId?: string;
  taskId?: string;
};

export default function useSubmitUploadForm({
  onNavigationClick,
  type,
  onFileUpload,
  taskId,
}: UseUploadFormHelperProps) {
  const navigate = useNavigate();
  const { mutateAsync: attachDocument, isLoading: filesAreAttaching } =
    useAttachDocument({ taskId });
  const { mutateAsync: sendNewDocument, isLoading: filesAreSending } =
    useSendNewDocument();
  const { mutateAsync: editFile, isLoading: fileUpdating } =
    useUpdateDocument();
  const { getValues, watch, setValue } = useFormContext();
  const [cancelControllersMap, setCancelControllersMap] =
    useState<CancelControllers>({});
  const [uploadProgressPercentMap, setUploadProgressPercentMap] =
    useState<FilePercentsMap>({});
  const isEditView = type === UploadFormType.Edit;
  const isSendNewFileView =
    type === UploadFormType.NewFile || type === UploadFormType.Workflow;

  async function handleEditDocument() {
    await editFile(
      {
        ...getValues(),
        multi_tags: getValues().tags.map((tag: { label: string }) => tag.label),
        document_ids: getValues().doc_ids[0],
      },
      {
        onSuccess: () => {
          if (onNavigationClick) {
            onNavigationClick('go:back');
          } else {
            const docId = getValues().doc_ids[0];
            navigate(`/${FILES_DOMAIN}${FILES_PATHS.details}/${docId}`);
          }
        },
      }
    );
  }

  function cancelUpload(file?: File) {
    if (!file) return;

    const fileId = makeFileId(file);
    cancelControllersMap[fileId].abort();

    delete cancelControllersMap[fileId];
    delete uploadProgressPercentMap[fileId];

    setUploadProgressPercentMap({ ...uploadProgressPercentMap });
    setCancelControllersMap({ ...cancelControllersMap });

    const { files: fileList } = getValues();
    const files = Array.from(fileList) as File[];
    const newFiles = files.filter(
      (oldFile: File) => makeFileId(oldFile) !== fileId
    );

    setValue('files', newFiles);
  }

  async function handleUploadDocument(formValues: UploadDocumentFormData) {
    const uploadFunction = isSendNewFileView ? sendNewDocument : attachDocument;
    const uploadType = FORM_TYPE_TO_UPLOAD_TYPE[type as TypesWithoutEdit];
    const params = {
      documentsConfig: {
        documents: formValues.files,
        taskId,
        uploadType,
        setCancelController: setCancelControllersMap,
        onUploadProgress: setUploadProgressPercentMap,
      } as UploadDocumentConfig,
      formValues: {
        ...formValues,
        id: taskId,
      } as UploadDocumentFormData & {
        id?: string;
      },
    };

    await uploadFunction(params, {
      onSuccess: (res) => {
        // for some reason, cancellation registers as a success with react-query
        // so interrupt the success script on cancellation (status 503)
        if (res?.doc_ids?.length === 0) return;

        if (onFileUpload)
          onFileUpload(
            type === UploadFormType.Workflow
              ? res?.doc_info || []
              : res?.doc_ids || [],
            formValues as UploadDocumentFormData
          );
        onNavigationClick('go:back');
      },
      onSettled: () => {
        setUploadProgressPercentMap({});
      },
    });
  }

  async function onSubmit(formValues: UploadDocumentFormData) {
    try {
      if (isEditView) handleEditDocument();
      else handleUploadDocument(formValues);
    } catch (err) {
      console.error(err);
    }
  }

  const disableSubmit = isEditView
    ? false
    : filesAreAttaching || filesAreSending || watch('files')?.length === 0;

  return {
    disableSubmit,
    filesAreUploading: filesAreAttaching || filesAreSending,
    isEditView,
    fileUpdating,
    onSubmit,
    uploadProgressPercentMap,
    cancelUpload,
  };
}
