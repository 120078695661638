import { useEffect, useMemo } from 'react';

import { Capacitor } from '@capacitor/core';
import toast from 'react-hot-toast';
import { Outlet, useNavigate } from 'react-router-dom';

import { GlobalLoader } from '@liscio/ui';

import { useHomeData } from 'fetch-utils/users/user-hooks';
import { LOGOUT_REDIRECT_URL } from 'modules/authenticate/route-constants';
import { useCpa } from 'stores/cpa';

/**
 * Route wrapper that checks user role ONLY after cpa selection
 * has occurred and gates users based on various conditions.
 *
 * NOTE: For now, the employee gating is ONLY done in Native for FirmDash.
 */
export const RequireFirmEmployeeRoute = () => {
  const { data: homeData, isLoading } = useHomeData();
  const { cpa } = useCpa();
  const navigate = useNavigate();

  const shouldLogoutClientUser = useMemo(() => {
    if (Capacitor.isNativePlatform()) {
      if (cpa && !isLoading && homeData && !homeData.data.is_employee) {
        return true;
      }
    }

    return false;
  }, [cpa, homeData, isLoading]);

  useEffect(() => {
    if (shouldLogoutClientUser) {
      toast.error('FirmDash is only available for Firm Employees');
      navigate(LOGOUT_REDIRECT_URL);
    }
  }, [shouldLogoutClientUser, navigate]);

  // Only enabled in FirmDash at present
  if (Capacitor.isNativePlatform()) {
    if (isLoading || shouldLogoutClientUser) {
      return <GlobalLoader open />;
    }
  }

  return <Outlet />;
};
