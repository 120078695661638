import { GlobalLoader } from '@liscio/ui';

import { useInitializedFlags } from '@liscio/common';
import { ForgotPasswordResetV5View } from './ForgotPasswordResetV5View/ForgotPasswordResetV5View';
import { ForgotPasswordResetV3View } from './ForgotPasswordResetV3View/ForgotPasswordResetV3View';

export const ForgotPasswordResetView = () => {
  const {
    isInitialized,
    flags: { v5Login },
  } = useInitializedFlags();

  if (!isInitialized) {
    return <GlobalLoader open />;
  }

  if (v5Login) {
    return <ForgotPasswordResetV5View />;
  }

  return <ForgotPasswordResetV3View />;
};
