import React from 'react';

import { Menu, MenuItem as MUIMenuItem, Avatar } from '@mui/material';

import { HomeCpaData, parseApiErrorMessage } from '@liscio/api';
import { NavMenuItem } from '@liscio/common';
import { GlobalLoader, Icon, Stack, useMenuAnchor } from '@liscio/ui';

import { useToastError } from 'custom-hooks';
import { useHomeData } from 'fetch-utils/users/user-hooks';
import { useCpa } from 'stores/cpa';

export interface SwitchCpaButtonProps {}

export const SwitchCpaButton: React.FC<SwitchCpaButtonProps> = () => {
  const { anchorEl, open, onOpen: handleMenuClick, onClose } = useMenuAnchor();

  const { data: homeData } = useHomeData();
  const { switchCpa, isLoading, error: switchCpaError } = useCpa();
  useToastError(
    switchCpaError,
    parseApiErrorMessage(switchCpaError) || 'Error: Unable to change workspaces'
  );
  const handleMenuItemClick = async (newCpaData?: HomeCpaData) => {
    onClose();
    if (newCpaData?.custom_liscio_url) {
      await switchCpa(newCpaData.custom_liscio_url);
    }
  };

  if (!homeData || !homeData.cpa_data || homeData.cpa_data.length === 0) {
    return null;
  }

  const availableCpaList = homeData.cpa_data.filter(
    (cpa) => cpa.id !== homeData.data.cpa_id
  );

  return (
    <>
      <NavMenuItem
        icon={<Icon icon="user" />}
        label={homeData.data.cpa_name}
        onClick={handleMenuClick}
        role="button"
      />
      <Menu
        id="cpa-menu"
        aria-labelledby="cpa-menu-button"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          handleMenuItemClick();
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {availableCpaList.map((cpa) => (
          <MUIMenuItem
            key={cpa.name}
            onClick={() => {
              handleMenuItemClick(cpa);
            }}
          >
            <Stack gap={1} direction="row" alignItems="center">
              <Avatar
                src={cpa.logo}
                alt={cpa.name}
                sx={{ width: 24, height: 24, fontSize: '12px' }}
              />
              {cpa.name}
            </Stack>
          </MUIMenuItem>
        ))}
      </Menu>
      <GlobalLoader open={isLoading} />
    </>
  );
};

export default SwitchCpaButton;
