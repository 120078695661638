import { useCallback, useMemo } from 'react';

import { Capacitor } from '@capacitor/core';
import { useQueryClient } from '@tanstack/react-query';

import { useGetApiV5Me } from '@liscio/api';
import { useCpaStore, useInitializedFlags } from '@liscio/common';

import { saveApplicationCpa, makeStateCpaFromV5Cpa } from './cpa-utils';
import { CpaStateItem } from './cpa.types';
import { useSwitchCpaMutationV5 } from 'fetch-utils/users/auth-hooks';

/**
 * Convenience hook for managing user Cpa(s)
 * NOTE: This hook should only be used in authorized views since it
 * utilizes home data which requires an auth token
 * TODO: Handle parsing cpa id from query parameters to select cpa
 */
export const useCpaV5 = () => {
  const {
    isInitialized: isFFInitialized,
    flags: { v5Login },
  } = useInitializedFlags();

  const { isLoading, isInitialized, sessionCpa, appCpa, setAppCpa } =
    useCpaStore();
  const {
    mutateAsync: switchCpaMutation,
    isLoading: isSwitchingCpa,
    error: switchCpaError,
  } = useSwitchCpaMutationV5();
  const queryClient = useQueryClient();

  const { data: meData, isLoading: isLoadingMeData } = useGetApiV5Me({
    query: { enabled: isFFInitialized && v5Login },
  });

  // List of user related cpas
  const cpas = useMemo(() => {
    const meDataCpa = meData?.cpa_data || [];
    return meDataCpa.map(makeStateCpaFromV5Cpa);
  }, [meData?.cpa_data]);

  // Select Application Cpa
  const selectCpa = useCallback(
    async (cpa: CpaStateItem) => {
      // Only switch cpa in backend if different from existing session cpa
      if (
        !sessionCpa ||
        sessionCpa.custom_liscio_url !== cpa.custom_liscio_url
      ) {
        await switchCpaMutation({ cpa_uuid: cpa.cpa_uuid! });
      }
      setAppCpa(cpa);
      await saveApplicationCpa(cpa);
    },
    [sessionCpa, setAppCpa, switchCpaMutation]
  );

  const switchCpa = useCallback(
    async (cpaUrl: string) => {
      const selectedCpa = cpas.find((cpa) => cpa.custom_liscio_url === cpaUrl);

      if (selectedCpa) {
        try {
          await switchCpaMutation({ cpa_uuid: selectedCpa.cpa_uuid! });

          const newCpa = { ...selectedCpa };
          setAppCpa(newCpa);
          await saveApplicationCpa(newCpa);

          if (Capacitor.isNativePlatform()) {
            // On native platforms we refresh the user session
            queryClient.invalidateQueries();
          } else {
            // On web, we redirect
            window.location.href = newCpa.custom_liscio_url || '';
          }
        } catch {}
      }
    },
    [switchCpaMutation, setAppCpa, cpas, queryClient]
  );

  return {
    cpa: appCpa,
    cpas,
    selectCpa,
    isLoading: !isInitialized || isLoading || isSwitchingCpa || isLoadingMeData,
    switchCpa,
    error: switchCpaError,
  };
};
