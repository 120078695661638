import { useQuery } from '@tanstack/react-query';

import { useInitializedFlags, useLogoutV5Process } from '@liscio/common';
import { GlobalLoader } from '@liscio/ui';

import { useAuth } from 'fetch-utils/users/use-auth';
import { clearApplicationCpa } from 'stores/cpa/cpa-utils';

/**
 * Route to handle destroying user
 */
export const LogoutView = () => {
  const {
    isInitialized,
    flags: { v5Login },
  } = useInitializedFlags();

  const { logout: logoutV3 } = useAuth();
  const { logout: logoutV5 } = useLogoutV5Process({
    onProcessFinished: async () => {
      await clearApplicationCpa();
    },
  });

  useQuery({
    queryKey: ['logout'],
    queryFn: () => {
      if (v5Login) {
        logoutV5();
      } else {
        logoutV3();
      }
    },
    retry: false,
    cacheTime: Infinity,
    enabled: isInitialized,
  });

  return <GlobalLoader open />;
};
