import { useCallback } from 'react';

import { Helmet } from 'react-helmet-async';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import { parseApiErrorMessage } from '@liscio/api';
import { useResetPasswordV5Process } from '@liscio/common';
import { styled, Box, ErrorAlert, GlobalLoader } from '@liscio/ui';

import { ResetPasswordFormV5 } from './components/ResetPasswordFormV5/ResetPasswordFormV5';
import { Copyright, Logo } from '../../LoginView/components';
import { MfaCodeForm } from 'modules/authenticate/components';
import { AUTHENTICATE_PAGE_TITLES } from 'modules/authenticate/route-constants';

const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  paddingBottom: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxWidth: theme.breakpoints.values.sm,
  [theme.breakpoints.up('md')]: {
    color: theme.palette.text.primary,
  },
}));

export const ForgotPasswordResetV5View = () => {
  const { token } = useParams<{ token: string }>();
  // Back to login
  const navigate = useNavigate();
  const backToLogin = useCallback(
    () => navigate('/authenticate/login', { replace: true }),
    [navigate]
  );

  const { processState, resetPassword, errors, isLoading, validateMfaCode } =
    useResetPasswordV5Process({
      token: token ?? '',
      onProcessFinished: () => {
        toast.success('Password changed successfully', { duration: 3000 });
        backToLogin();
      },
    });

  return (
    <>
      <Helmet title={AUTHENTICATE_PAGE_TITLES.forgotPasswordReset} />
      <Wrapper>
        <Logo />
        <Box mt={2}>
          <ErrorAlert error={errors} parseError={parseApiErrorMessage} />
          {processState === 'newPasswordForm' && (
            <ResetPasswordFormV5
              onSubmit={resetPassword}
              isLoading={isLoading}
            />
          )}
          {processState === 'mfaCode' && (
            <MfaCodeForm
              backToLogin={backToLogin}
              isLoading={isLoading}
              onSubmit={({ mfaCode }) => {
                validateMfaCode(mfaCode);
              }}
            />
          )}
        </Box>
        <Box mt={2}>
          <Copyright />
        </Box>
        <GlobalLoader open={isLoading} />
      </Wrapper>
    </>
  );
};
