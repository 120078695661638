import React, { useLayoutEffect, useMemo } from 'react';

import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useLocation, matchPath, Outlet } from 'react-router-dom';

import { useInitializedFlags } from '@liscio/common';

import { conditionalExclusionPaths, deferredPaths } from './desktop-redirects';
import {
  getIsDesktopRedirectDevice,
  getDesktopSiteRedirect,
} from './desktop-site-redirect-utils';

export interface DesktopSiteRedirectProps {
  children?: React.ReactNode;
}

export const DesktopSiteRedirect = ({ children }: DesktopSiteRedirectProps) => {
  const location = useLocation();
  const ldClient = useLDClient();
  const { isInitialized, flags } = useInitializedFlags();

  // temporary flag will need to be swapped out for a permanent one to only fire for specific firms
  const { devFirmRequestsIndexPage: devFirmRequestsIndexPageFlag } = flags;

  // Allows additionals routes from LaunchDarkly, etc calculated at render time
  const additionalRoutes = () => {
    const routes = [];
    if (devFirmRequestsIndexPageFlag) {
      routes.push({ path: conditionalExclusionPaths.elasticSearchRequests });
    }
    return routes;
  };

  const redirectUrl = getDesktopSiteRedirect(
    window.location,
    additionalRoutes()
  );

  const isDesktopRedirectDevice = getIsDesktopRedirectDevice(
    window.navigator.userAgent
  );
  const shouldDeferRendering = useMemo(
    () =>
      Boolean(
        deferredPaths.some((pattern) => matchPath(pattern, location.pathname))
      ),
    [location]
  );

  // Handle Desktop redirect
  useLayoutEffect(() => {
    if (isInitialized) {
      if (redirectUrl && isDesktopRedirectDevice) {
        window.location.replace(redirectUrl);
        // Render nothing to prevent one-time-use tokens from being consumed
      }
    }
  }, [redirectUrl, isDesktopRedirectDevice, isInitialized]);

  // Defer rendering until launch darkly has loaded for configured endpoints
  if (ldClient === undefined && shouldDeferRendering) {
    return <Outlet />;
  }

  return children || <Outlet />;
};
