import { NestedMenuItem, MenuItem, Icon } from '@liscio/ui';

import { routes } from 'routes';

type FirmAdminUserMenuProps = {
  open: boolean;
};

export const FirmAdminUserMenu = ({ open }: FirmAdminUserMenuProps) => (
  <>
    <NestedMenuItem
      label="Admin"
      parentMenuOpen={open}
      leftIcon={<Icon icon="admin" />}
      slotProps={{ paper: { sx: { minWidth: '200px' } } }}
    >
      <MenuItem sx={{ padding: '16px' }} to={routes.adminUsers.link()}>
        Users
      </MenuItem>
      <MenuItem sx={{ padding: '16px' }} to={routes.adminPreferences.link()}>
        Preferences
      </MenuItem>
      <MenuItem sx={{ padding: '16px' }} to={routes.adminTemplates.link()}>
        Templates
      </MenuItem>
      <MenuItem sx={{ padding: '16px' }} to={routes.adminIntegrations.link()}>
        Integrations
      </MenuItem>
      <MenuItem sx={{ padding: '16px' }} to={routes.adminSMS.link()}>
        Admin SMS
      </MenuItem>
      <MenuItem sx={{ padding: '16px' }} to={routes.adminBilling.link()}>
        System Billing
      </MenuItem>
    </NestedMenuItem>
  </>
);
