import { useCallback } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { parseApiErrorMessage } from '@liscio/api';
import { Button, Stack, styled, Typography } from '@liscio/ui';
import { defaultErrorParser } from '@liscio/utils';

import { validationSchema } from './validationSchema';
import { useYupValidationResolver } from 'custom-hooks';
import { AuthPasswordInput } from 'modules/authenticate/components/FormFields';

export interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

export const parseError = (error?: unknown) => {
  const apiErrorMessage = parseApiErrorMessage(error);

  return apiErrorMessage || defaultErrorParser(error);
};

const FormStyled = styled('form')(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const ResetPasswordFormV5 = ({
  onSubmit: onSubmitProp,
  isLoading,
}: {
  onSubmit: (newPassword: string) => void;
  isLoading: boolean;
}) => {
  // Back to login
  const navigate = useNavigate();
  const backToLogin = useCallback(
    () => navigate('/authenticate/login', { replace: true }),
    [navigate]
  );

  // Form
  const validateResolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormData>({
    resolver: validateResolver,
  });

  const onSubmit: SubmitHandler<ResetPasswordFormData> = useCallback(
    async (data) => {
      onSubmitProp(data.password);
    },
    [onSubmitProp]
  );

  return (
    <>
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h3" textAlign="center" mb={4} mt={4}>
          Password Reset
        </Typography>
        <Stack mt={2} gap={2}>
          <AuthPasswordInput
            {...register('password')}
            placeholder="New Password"
            error={Boolean(errors.password)}
            helperText={errors?.password?.message}
            disabled={isLoading}
          />
          <AuthPasswordInput
            {...register('password_confirmation')}
            placeholder="Confirm Password"
            error={Boolean(errors.password_confirmation)}
            helperText={errors?.password_confirmation?.message}
            disabled={isLoading}
          />
          <Stack mt={2} gap={2}>
            <Button
              disabled={isLoading}
              variant="contained"
              type="submit"
              size="large"
            >
              Submit
            </Button>
            <Button
              variant="contained"
              type="button"
              size="large"
              color="secondary"
              onClick={backToLogin}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </FormStyled>
    </>
  );
};
