import React, { useState } from 'react';

import { useFieldArray } from 'react-hook-form';

import { Role } from '@liscio/api';
import { Alert, Button, Stack } from '@liscio/ui';

import { ContactSelector } from 'components';

export interface AdditionalParticipantFormProps {
  warningMessage: string;
}

export const AdditionalParticipantForm: React.FC<
  AdditionalParticipantFormProps
> = ({ warningMessage }) => {
  const [isContactSelectorOpen, setIsContactSelectorOpen] = useState(false);
  const { append } = useFieldArray({ name: 'participants' });

  return (
    <Stack gap={2}>
      <Alert severity="error">{warningMessage}</Alert>
      <Button
        onClick={() => setIsContactSelectorOpen(true)}
        variant="contained"
      >
        Add
      </Button>
      <ContactSelector
        open={isContactSelectorOpen}
        onCancel={() => setIsContactSelectorOpen(false)}
        onChange={({ recipient, account }) =>
          append({
            account_id: account?.value,
            account_name: account?.label,
            assignee_type: Role.User,
            contact_name: recipient?.label,
            id: recipient?.value,
            type: Role.User,
          })
        }
        useOnlyEmployees
      />
    </Stack>
  );
};

export default AdditionalParticipantForm;
