import { useEffect } from 'react';

import { useCpaStore, useInitializedFlags } from '@liscio/common';

import { getSavedApplicationCpa } from './cpa-utils';
import { useCpaV3 } from './useCpaV3';
import { useCpaV5 } from './useCpaV5';
import { useHomeData } from 'fetch-utils/users/user-hooks';

const useCpaDefaultValues: ReturnType<typeof useCpaV5> = {
  cpa: undefined,
  cpas: [],
  error: undefined,
  isLoading: true,
  selectCpa: () => Promise.resolve(),
  switchCpa: () => Promise.resolve(),
};

const getUseCpaValues = ({
  isFFInitialized,
  v5Login,
  useCpaV3Values,
  useCpaV5Values,
}: {
  isFFInitialized: boolean;
  v5Login: boolean;
  useCpaV5Values: ReturnType<typeof useCpaV5>;
  useCpaV3Values: ReturnType<typeof useCpaV3>;
}) => {
  if (!isFFInitialized) {
    return useCpaDefaultValues;
  }
  if (v5Login) {
    return useCpaV5Values;
  }
  return useCpaV3Values;
};

export const useCpa = () => {
  const {
    isInitialized: isFFInitialized,
    flags: { v5Login },
  } = useInitializedFlags();
  const {
    isLoading,
    setIsLoading,
    isInitialized,
    setIsInitialized,
    sessionCpa,
    appCpa,
    queryCompany,
    setQueryCompany,
    setSessionCpa,
  } = useCpaStore();
  const { data: homeData } = useHomeData();

  //useCpaV5
  const useCpaV5Values = useCpaV5();
  //useCpaV3
  const useCpaV3Values = useCpaV3();
  //useCpaValues
  const {
    cpa,
    cpas,
    error,
    isLoading: isHookLoading,
    selectCpa,
    switchCpa,
  } = getUseCpaValues({
    isFFInitialized,
    v5Login,
    useCpaV3Values,
    useCpaV5Values,
  });

  // Handle loading session cpa from homeData
  useEffect(() => {
    const userSessionData = homeData?.data;
    if (
      userSessionData &&
      userSessionData.cpa_id &&
      userSessionData.company_url &&
      userSessionData.cpa_id !== sessionCpa?.id
    ) {
      setSessionCpa({
        id: userSessionData.cpa_id,
        name: userSessionData.cpa_name,
        custom_liscio_url: userSessionData.company_url,
        logo: userSessionData.cpa_logo,
      });
    }
  }, [sessionCpa, setSessionCpa, homeData?.data]);

  // App CPA initialization
  useEffect(() => {
    // NOTE: This only gets called when already authenticated
    const initCpa = async () => {
      // Set loading state to avoid multiple executions
      setIsLoading(true);

      // autoset cpa when only 1 available
      if (cpas.length === 1 && !appCpa) {
        selectCpa(cpas[0]);
      } else if (cpas.length > 1) {
        // Multiple cpas
        // Check for cpa from storage
        const savedCpa = await getSavedApplicationCpa();
        if (savedCpa && cpas.length > 1) {
          // Validate saved cpa against user cpas
          const validatedCpa = cpas.find(
            (cpa) => cpa.custom_liscio_url === savedCpa.custom_liscio_url
          );
          if (validatedCpa) {
            selectCpa(validatedCpa);
          }
        }

        // Handle company_name query param (will overwrite stored cpa)
        if (queryCompany && cpas.length > 1) {
          // Validate query company_name against user cpas
          const validatedCpa = cpas.find(
            (cpa) =>
              cpa.custom_liscio_url?.replace(/^(https:\/\/)?/, '') ===
              queryCompany
          );
          if (validatedCpa) {
            selectCpa(validatedCpa);
          } else {
            // Clear invalid query company_name
            setQueryCompany(undefined);
          }
        }
      }

      // init complete
      setIsInitialized(true);
      setIsLoading(false);
      setQueryCompany(undefined);
    };

    if (!isInitialized && !isLoading && cpas.length && sessionCpa) {
      initCpa();
    }
  }, [
    cpas,
    sessionCpa,
    appCpa,
    selectCpa,
    queryCompany,
    setQueryCompany,
    isLoading,
    setIsLoading,
    isInitialized,
    setIsInitialized,
  ]);

  return {
    cpa,
    cpas,
    error,
    isLoading: isLoading || isHookLoading,
    selectCpa,
    switchCpa,
  };
};
